import styled from 'styled-components';

import { theme } from '../../../../styles/styled';

export const EmptyMessage = styled.div`
  padding: ${theme.constant.gridPx(1)};
  text-align: center;
  font-weight: 500;
  font-size: ${theme.constant.fontSize.standard};
  line-height: ${theme.constant.gridPx(3)};
`;
