import { RESULTS_NOT_DISPLAYED_MESSAGE } from '../constants';

export const getResultsLimitMessage = (
  numResults: number,
  maxSearchResults: number,
  apiResultsArePaginated: boolean
): string => {
  if (apiResultsArePaginated) {
    return RESULTS_NOT_DISPLAYED_MESSAGE;
  }

  const remainingResultsCount = numResults - maxSearchResults;

  return remainingResultsCount > 0 ? `${remainingResultsCount} more results not displayed. ` : '';
};
