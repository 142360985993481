import './EntitySearch.scss';

import type { ApolloError } from '@apollo/client';
import { SearchField, SearchResults } from '@hulu/react-style-components';
import { debounce } from 'lodash';
import React, { useState } from 'react';

import bem, { withOptionalClassName } from '../../utils/bem';
import Alert, { AlertType } from '../Alert';
import LoaderSvg from '../assets/Loader.svg';
import { DEBOUNCE_DURATION_MS } from './constants';
import SearchResultList from './SearchResultList';
import SearchResultMessages from './SearchResultMessages';
import { SearchResultsWrapper } from './SearchResultsWrapper';

export type BaseEntity = {
  id: string;
  name: string;
};

type SearchHookResults<T extends BaseEntity> = {
  error?: ApolloError;
  hasMore?: boolean;
  loading: boolean;
  onNext?: () => void;
  results: T[]; // return empty array if no results are returned by API
};

export interface EntitySearchProps<T extends BaseEntity> {
  apiResultsArePaginated: boolean; // API paginates results. If false, limit the number of results displayed in the component
  className?: string;
  expandedEntityDetails?: React.FC<T>;
  hasPagination?: boolean;
  label: string;
  maxSearchResults: number; // Limit displayed search results and display a helpful message
  onSelect: (entity: T) => void;
  readonly?: boolean;
  searchHook: (query: string) => SearchHookResults<T>;
  style?: React.CSSProperties;
}

const [block, element] = bem('entity-search');

const EntitySearch = <T extends BaseEntity>({
  apiResultsArePaginated,
  className,
  expandedEntityDetails,
  hasPagination,
  label,
  maxSearchResults,
  onSelect,
  readonly,
  searchHook,
  style,
}: EntitySearchProps<T>): React.JSX.Element => {
  const [query, setQuery] = useState('');

  const { loading, error, results, hasMore, onNext } = searchHook(query);

  const numResults = results.length;
  const maxSearchResultsScrolled = Boolean(numResults && !hasMore);
  const maxSearchResultsReached = hasPagination ? maxSearchResultsScrolled : numResults >= maxSearchResults;
  const searchResults = hasPagination ? results : results.slice(0, maxSearchResults);

  return (
    <div className={withOptionalClassName(block(), className)} style={style} data-testid="entity-search">
      <SearchField
        label={label}
        className={element('input')}
        onInput={debounce((input) => setQuery(input.trim()), DEBOUNCE_DURATION_MS)}
        placeholder="Search"
        disabled={readonly}
      />
      {loading && <img className={element('loading-spinner')} src={LoaderSvg} alt="loading spinner" />}
      <SearchResults isOpen={!loading && !!query} id="scrollableDiv">
        <SearchResultsWrapper
          hasMore={hasMore}
          hasPagination={hasPagination}
          loaderClass={element('loader')}
          numResults={numResults}
          onNext={onNext}
        >
          <>
            <SearchResultList
              searchResults={searchResults}
              onSelect={onSelect}
              query={query}
              expandedEntityDetails={expandedEntityDetails}
            />
            <SearchResultMessages
              maxSearchResults={maxSearchResults}
              maxSearchResultsReached={maxSearchResultsReached}
              numResults={numResults}
              apiResultsArePaginated={apiResultsArePaginated}
            />
          </>
        </SearchResultsWrapper>
      </SearchResults>
      {error && <Alert type={AlertType.ERROR} bodyText={error.message} />}
    </div>
  );
};

export default EntitySearch;
