import { orderBy as _orderBy } from 'lodash';

import type { TargetingCategory, TargetingDimension, TargetingValue } from '../../../models';
import { SortState } from '../../SortArrow/constants';
import type { TargetingTableRowGroup } from '../TargetingTable';

export function sanitizeString(str: string): string {
  return str.replace(/[^\p{L}\p{N}]/gu, '').toLocaleLowerCase();
}
function getItemName(name: string, displayName: string): string {
  return displayName || name || '';
}
function getCategoryName(category: TargetingCategory): string {
  return sanitizeString(getItemName(category?.name, category?.displayName));
}
function getDimensionName(dimension: TargetingDimension): string {
  return sanitizeString(getItemName(dimension?.name, dimension?.displayName));
}
function getValueName(value: TargetingValue): string {
  return sanitizeString(getItemName(value?.name, value?.displayName));
}

export const sortTargetingTableRowGroups = (
  targetingTableRowGroups: TargetingTableRowGroup[],
  sortState: SortState
): TargetingTableRowGroup[] => {
  const sortTargetingTableRowGroupByAscOrDesc = (
    sortStateAscOrDesc: SortState.SORTED_ASC | SortState.SORTED_DESC
  ): TargetingTableRowGroup[] => {
    const sortedGroups = _orderBy(
      targetingTableRowGroups,
      ['groupType'],
      [sortStateAscOrDesc === SortState.SORTED_ASC ? 'asc' : 'desc']
    ).map((rowGroup) => ({
      ...rowGroup,
      targetingTermValues: _orderBy(
        rowGroup.targetingTermValues,
        [
          (termValue): string => getCategoryName(termValue.category).toLocaleLowerCase(),
          (termValue): string => getDimensionName(termValue.dimension).toLocaleLowerCase(),
          (termValue): string => getValueName(termValue.value).toLocaleLowerCase(),
        ],
        [
          sortStateAscOrDesc === SortState.SORTED_ASC ? 'asc' : 'desc',
          sortStateAscOrDesc === SortState.SORTED_ASC ? 'asc' : 'desc',
          sortStateAscOrDesc === SortState.SORTED_ASC ? 'asc' : 'desc',
        ]
      ),
    }));

    return sortedGroups;
  };

  const sortTargetingTableRowGroupsMap = {
    [SortState.SORTED_ASC]: sortTargetingTableRowGroupByAscOrDesc(SortState.SORTED_ASC),
    [SortState.SORTED_DESC]: sortTargetingTableRowGroupByAscOrDesc(SortState.SORTED_DESC),
    [SortState.UNSORTED]: targetingTableRowGroups,
  };

  return sortTargetingTableRowGroupsMap[sortState];
};
