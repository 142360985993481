import './CreativeBuilder.scss';

import { IconCheck, IconSlash } from '@hulu-react-style-components/icons';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import type { GetCreativeByIdQuery } from '../../../../apis/graphql';
import { AdStatusV5, Review } from '../../../../apis/graphql';
import TextField from '../../../../common/Form/TextField';
import bem, { withOptionalClassName } from '../../../../utils/bem';
import Loader from '../../../Loader';
import ToggleSwitch from '../../../ToggleSwitch';
import type { AdFormValues } from '../../adFormik';
import { AdFormCreativeFields } from '../../adFormik/fields';
import RemoveCreativeButton from '../../RemoveCreativeButton';
import type { AdsPageLineItemType } from '../../types';
import { updateCreativeMetaDataByCreativeId } from '../hooks/updateCreativeMetadataByCreativeId';
import { useCreativeMetadataByCreativeId } from '../hooks/useCreativeMetadataByCreativeId';
import { CreativeEditMode } from '../types';

export type Size = { width: number; height: number };
export type Dimensions = { fileSize?: number; sizes: Size[]; isJPGTypeAllowed?: boolean };

export type AdDimensionsType = {
  option?: {
    video?: Dimensions;
    display: Dimensions;
  };
  orientation?: {
    landscape: Dimensions;
    portrait: Dimensions;
  };
  logo?: Dimensions;
  video?: Dimensions;
  display?: Dimensions;
};
export interface CreativeBuilderProps {
  editMode: CreativeEditMode;
  isPreview?: boolean;
  children?: React.ReactNode;
  classNameModifier?: string;
  adTypeDimensions?: AdDimensionsType;
  lineItemType?: AdsPageLineItemType;
  creativeData?: GetCreativeByIdQuery;
  loadingCreativeData?: boolean;
  isAdApproved?: boolean;
}

export const [block, element] = bem('creative-builder');

function CreativeBuilder(props: CreativeBuilderProps): React.JSX.Element {
  const { values: adFormValues } = useFormikContext<AdFormValues>();
  const [legalState, setLegalState] = useState(false);

  const creativeId: string | undefined = adFormValues.creative?.id;
  const isExistingCreative = !!creativeId;
  const { metadataLoading, creativeMetadata } = useCreativeMetadataByCreativeId(creativeId, true);

  const isRemoveForbidden =
    props.editMode === CreativeEditMode.readonly ||
    (adFormValues.status?.key === AdStatusV5.On && adFormValues.review?.key === Review.Approved);

  const onLegalToggleChange = (): void => {
    setLegalState(!legalState);
    updateCreativeMetaDataByCreativeId(creativeId, creativeMetadata?.countryCodes || [], !legalState);
  };

  useEffect(() => {
    setLegalState(creativeMetadata?.isLegalApproved ?? false);
  }, [creativeMetadata?.isLegalApproved, creativeId]);

  return (
    <div className={withOptionalClassName(block(props.classNameModifier))}>
      <div className={'top-grid-container'}>
        <TextField
          id="creativeName"
          formFieldName={AdFormCreativeFields.Name}
          className={element('creative-name-input')}
          label={isExistingCreative ? 'Creative Name' : 'New Creative Name'}
          secondaryLabel={props.isPreview ? undefined : ' (Required)'}
          isPreview={isExistingCreative || props.isPreview}
          disabled={props.editMode !== CreativeEditMode.full}
        />

        {isExistingCreative && (
          <>
            <div>
              <span className={element('field-label')}>Legal-Approved Creative</span>
              {metadataLoading ? (
                <Loader />
              ) : (
                <ToggleSwitch
                  isOn={legalState}
                  onChange={onLegalToggleChange}
                  checkedIcon={<IconCheck />}
                  uncheckedIcon={<IconSlash />}
                  className={`toggle-button`}
                  toggleTooltip={`Toggle to true if assets are already approved by Legal`}
                />
              )}
            </div>
            <RemoveCreativeButton
              creativeName={adFormValues.creative.name}
              adType={adFormValues.type}
              readonly={isRemoveForbidden}
            />
          </>
        )}
      </div>

      {/*
        IMPORTANT: this renders each creative builder's specific layout
        (i.e. form fields, asset uploaders, and preview displays)
        */}
      {props.children}
    </div>
  );
}

export default CreativeBuilder;
