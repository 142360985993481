import { AdTypeV5, CreativeV5Type } from '../apis/graphql';
import type { BrightlineTemplate, CreativeType, InnovidTemplate } from '.';
import { brightlineTemplates } from './brightline';
import { innovidTemplates } from './innovid';

export type CreativeTemplate = BrightlineTemplate | InnovidTemplate;

const adTypesWithTemplate = [
  AdTypeV5.AdSelector,
  AdTypeV5.Brightline,
  AdTypeV5.GatewayShop,
  AdTypeV5.Innovid,
  AdTypeV5.MaxSelector,
] as const;

type AdTypesWithTemplate = typeof adTypesWithTemplate[number];

const permittedAdTemplates: Record<AdTypesWithTemplate, CreativeTemplate[]> = {
  AD_SELECTOR: [brightlineTemplates.AD_SELECTOR.key],
  BRIGHTLINE: [
    brightlineTemplates.DYNAMIC_ADDRESSABLE.key,
    brightlineTemplates.EXPANDABLE.key,
    brightlineTemplates.INSTREAM_INTERACTIVE.key,
    brightlineTemplates.TEST_BRIGHTLINE.key,
  ],
  GATEWAY_SHOP: [brightlineTemplates.GATEWAY_SHOP.key],
  INNOVID: [
    innovidTemplates.CANVAS.key,
    innovidTemplates.ENDCARD.key,
    innovidTemplates.EXPANDABLE.key,
    innovidTemplates.OVERLAY.key,
    innovidTemplates.TEST_INNOVID.key,
  ],
  MAX_SELECTOR: [brightlineTemplates.MAX_SELECTOR.key],
};

const isGatewayShopDxcCreative = (adType: AdTypeV5, creativeType: CreativeType): boolean =>
  adType === AdTypeV5.GatewayShop && creativeType === CreativeV5Type.InstreamDxc;

const isAdHasTemplate = (adType: AdTypeV5): adType is AdTypesWithTemplate => {
  return adTypesWithTemplate.includes(adType as AdTypesWithTemplate);
};

export const isCreativeTemplatePermitted = (
  adType: AdTypeV5,
  creativeType: CreativeType,
  template?: string
): boolean => {
  if (!isGatewayShopDxcCreative(adType, creativeType) && isAdHasTemplate(adType)) {
    return template ? permittedAdTemplates[adType].includes(template as CreativeTemplate) : false;
  }

  return true;
};

export const getAdPermittedTemplateTypes = (adType: AdTypeV5): CreativeTemplate[] => {
  return isAdHasTemplate(adType) ? permittedAdTemplates[adType] : [];
};
