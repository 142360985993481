import { SearchResultItem } from '@hulu/react-style-components';
import React from 'react';

import { NARROW_SEARCH_PROMPT } from '../constants';
import { getResultsLimitMessage } from './utils';

export type SearchResultMessagesProps = {
  maxSearchResultsReached: boolean;
  numResults: number;
  maxSearchResults: number;
  apiResultsArePaginated: boolean;
};

const SearchResultMessages = ({
  maxSearchResults,
  maxSearchResultsReached,
  numResults,
  apiResultsArePaginated,
}: SearchResultMessagesProps): React.JSX.Element | null => {
  if (!maxSearchResultsReached) return null;

  return (
    <SearchResultItem className="max-results-prompt">
      {getResultsLimitMessage(numResults, maxSearchResults, apiResultsArePaginated)}
      {NARROW_SEARCH_PROMPT}
    </SearchResultItem>
  );
};

export default SearchResultMessages;
