import { bem } from 'mission-control-common-components';
import React from 'react';

import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import type { CreativeReviewStatus } from '../../../../../configs';
import type { Nullable } from '../../../../../models';
import Tooltip from '../../../../Tooltip';
import CreativeReviewBadge from '../../../CreativeReviewBadge';
import { ingestButton } from '../../configs';
import { reviewAlertPropsMap } from '../../utils';
import { CreativeFooterStyling } from '../CreativeFooterStyling';
import { SetAssetErrorButton } from '../SetAssetErrorButton';

const element = bem('creative-footer')[1];

export const SiteServedFooter: React.FC<{
  assets?: Nullable<AssetExtended>[] | null;
  isLoading?: boolean;
  readonly?: boolean;
  hrLine?: boolean | undefined;
  creativeReview?: CreativeReviewStatus;
}> = ({ assets, isLoading, readonly, hrLine = true, creativeReview }) => {
  const reviewAlertProps = creativeReview ? reviewAlertPropsMap?.[creativeReview] : null;
  const spReviewLocked = assets?.some((asset) => asset && asset.reviewTask?.spReview === 'LOCKED');

  if (!reviewAlertProps || spReviewLocked) return null;

  const hasTranscodeAbort = assets?.some((asset) => asset && asset.transcodeStatus === 'PUBLISHING_ABORT');

  const { tooltipConfig, tooltipText } = ingestButton;

  return (
    <CreativeFooterStyling>
      {hrLine && <hr />}
      <div className={element('split-info-wrapper')}>
        <div className={element('content', 'information')}>
          <CreativeReviewBadge loading={isLoading} alertProps={reviewAlertProps} creativeReview={creativeReview} />
        </div>
        {hasTranscodeAbort && (
          <div className={element('content', 'interaction')}>
            <Tooltip id="vast-footer-tooltip" className={element('tooltip')} message={tooltipText} {...tooltipConfig}>
              <SetAssetErrorButton readonly={readonly} assets={assets} />
            </Tooltip>
          </div>
        )}
      </div>
    </CreativeFooterStyling>
  );
};
