import { colorBlueBase } from '@hulu/design-tokens/lib/colors.common';
import styled from 'styled-components';

import { gridPx } from '../../../../styles/styled/constants';

export const RejectionReasonsActivator = styled.div`
  font-size: 12px;
  color: ${colorBlueBase};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  text-transform: none;
  margin: 0 ${gridPx(0.5)};
`;
