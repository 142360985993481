import { SearchResultItem } from '@hulu/react-style-components';
import React from 'react';
import Highlighter from 'react-highlight-words';

import { RESULT_NOT_FOUND } from '../constants';
import type { BaseEntity } from '../EntitySearch';

export type SearchResultListProps<T extends BaseEntity> = {
  searchResults: T[];
  query: string;
  expandedEntityDetails?: React.FC<T>;
  onSelect: (entity: T) => void;
};

const SearchResultList = <T extends BaseEntity>({
  searchResults,
  query,
  expandedEntityDetails,
  onSelect,
}: SearchResultListProps<T>): React.JSX.Element => {
  return (
    <>
      {searchResults.map((entity) => (
        <SearchResultItem key={entity.id} onClick={(): void => onSelect(entity)}>
          <Highlighter
            highlightClassName="search-term"
            searchWords={[query]}
            autoEscape
            textToHighlight={entity.name}
            className="creative-name"
          />
          {expandedEntityDetails?.(entity) || <span className="creative-id">{entity.id}</span>}
        </SearchResultItem>
      ))}
      {!searchResults.length && <SearchResultItem className="no-results-prompt">{RESULT_NOT_FOUND}</SearchResultItem>}
    </>
  );
};

export default SearchResultList;
