import { useQuery } from '@apollo/client';
import { debounce as _debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import type { Get_AgenciesQuery, Get_BrandsQuery, Get_Industries_V5Query } from '../../../apis/graphql';
import type { DropdownOption } from '../../Dropdown';
import type { MultiDropdownOptions } from '../config';
import { MULTI_DROPDOWN_OPTIONS_CONFIG } from '../config';
import { DEFAULT_DEBOUNCE_TIMEOUT, DEFAULT_FILTER_LIMIT, DEFAULT_FILTER_OFFSET } from '../constants';

export type UseFetchMultiDropdownOptions = {
  hasMore: boolean;
  searching: boolean;
  searchTerm: string;
  error?: string;
  options: DropdownOption<string>[];
  onNext: () => Promise<void>;
  onResetOptions: () => void;
  onSearch: (searchTerm: string) => void;
};

export type MultiDropdownQueryType = Get_BrandsQuery & Get_AgenciesQuery & Get_Industries_V5Query;

export const useFetchMultiDropdownOptions = <T extends MultiDropdownQueryType>(
  dropdownOption: MultiDropdownOptions
): UseFetchMultiDropdownOptions => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [queryData, setQueryData] = useState<T | null>();

  const {
    schema,
    queryName,
    queryOptions,
    additionalQueryVariables = {},
    queryOffset,
    queryPageInfo,
    convertFetchedData,
  } = MULTI_DROPDOWN_OPTIONS_CONFIG[dropdownOption];

  const { data, error, fetchMore, refetch } = useQuery<T>(schema, {
    ...queryOptions,
  });

  const hasMore = !!queryPageInfo(queryData)?.hasNextPage;

  const options = useMemo(() => convertFetchedData(queryData ?? data), [queryData, convertFetchedData, data]);

  const debouncedSearch = useMemo(
    () =>
      _debounce(async (searchTerm: string): Promise<void> => {
        try {
          setSearching(true);

          const { data } = await refetch({
            ...additionalQueryVariables,
            searchTerm,
            offset: DEFAULT_FILTER_OFFSET,
            limit: DEFAULT_FILTER_LIMIT,
          });
          setQueryData(data);

          setSearching(false);
        } catch (e) {
          console.error(e);
        }
      }, DEFAULT_DEBOUNCE_TIMEOUT),
    [refetch, additionalQueryVariables]
  );

  const onSearch = useCallback(
    (searchTerm: string): void => {
      setSearchTerm(searchTerm);
      setSearching(true);
      setQueryData(null);

      debouncedSearch(searchTerm);
    },
    [debouncedSearch]
  );

  const onNext = async (): Promise<void> => {
    try {
      const newOffset = queryOffset(queryData);

      const newData = await fetchMore({
        variables: {
          searchTerm,
          limit: DEFAULT_FILTER_LIMIT,
          offset: newOffset,
        },
      });

      setQueryData((prevState) => {
        if (!prevState) {
          return newData.data;
        }

        const prevData = prevState[queryName];
        const newDataEdges = newData.data[queryName]?.edges || [];
        const mergedEdges = [...(prevData?.edges || []), ...newDataEdges];

        return {
          ...prevState,
          [queryName]: {
            ...prevData,
            edges: mergedEdges,
          },
        };
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onResetOptions = (): void => {
    setSearchTerm('');
    setSearching(false);
    setQueryData(null);
  };

  return {
    hasMore,
    searching,
    error: error?.message,
    searchTerm,
    options,
    onNext,
    onResetOptions,
    onSearch,
  };
};
