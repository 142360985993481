import { bem } from 'mission-control-common-components';
import React from 'react';

import type { CreativeReviewStatus } from '../../../../../configs';
import { creativeReviewStatuses } from '../../../../../configs';
import type { Nullable } from '../../../../../models';
import Alert from '../../../../Alert';
import { CreativeFooterStyling } from '../../components';
import { reviewAlertPropsMap } from '../../utils';
import RejectionMessage from '../RejectionMessage';

type Props = {
  creativeReview?: CreativeReviewStatus;
  rejectionReason?: Nullable<string>;
  rightSideComponent?: React.JSX.Element | React.JSX.Element[];
};

const creativeFooterElement = bem('creative-footer')[1];

const InstreamDxcCreativeFooter = ({
  creativeReview,
  rejectionReason,
  rightSideComponent,
}: Props): React.JSX.Element => {
  const review = creativeReview ? reviewAlertPropsMap?.[creativeReview] : null;
  const reviewMessage =
    creativeReview === creativeReviewStatuses.REJECTED.key ? (
      <RejectionMessage bodyText={review?.bodyText} rejectionReason={rejectionReason} />
    ) : (
      review?.bodyText
    );

  return (
    <CreativeFooterStyling>
      <hr />
      <div className={creativeFooterElement('split-info-wrapper')}>
        <div className={creativeFooterElement('content', 'information')}>
          {review ? <Alert {...review} bodyText={reviewMessage} /> : null}
        </div>
        <div className={creativeFooterElement('content', 'interaction')}>{rightSideComponent}</div>
      </div>
    </CreativeFooterStyling>
  );
};

export default InstreamDxcCreativeFooter;
