import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

import type { ChangeLog, ChangeLogEntryEdge, SingleValueChange } from '../../apis/graphql';

export const GET_REVIEW_STATUS_MODAL_CHANGE_LOG = gql`
  query GetReviewModalStatusChangeLog(
    $id: ID!
    $entityType: EntityType
    $paginationOptions: PaginationOptions
    $filterOptions: GetChangelogFilterOptions
  ) {
    getChangeLogByAdObjectID(
      id: $id
      entityType: $entityType
      paginationOptions: $paginationOptions
      filterOptions: $filterOptions
    ) {
      total
      edges {
        node {
          audits {
            modifiedDate
            modifiedBy
            changeList {
              fieldName
              __typename
              ... on SingleValueChange {
                __typename
                oldValue {
                  action
                  payload
                }
                newValue {
                  action
                  payload
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function useAssetComments(assetId?: string) {
  const { data, loading, error } = useQuery(GET_REVIEW_STATUS_MODAL_CHANGE_LOG, {
    variables: {
      id: assetId,
      entityType: 'AD',
      paginationOptions: {
        limit: 100,
      },
      filterOptions: {},
    },
    skip: !assetId,
  });
  const changeLogComments = getChangelogComments(data?.getChangeLogByAdObjectID);

  return { changeLogComments, loading, error };
}

export type CommentsData = {
  user?: string;
  date?: string;
  comment?: string;
};

function dateFormatter(
  date: Date,
  timeStringOptions: Intl.DateTimeFormatOptions,
  includeTime: boolean,
  dateStringOptions?: Intl.DateTimeFormatOptions
): string {
  if (date === undefined || date === null || isNaN(date.getTime())) return 'Not Reviewed';

  const dateString = date.toLocaleDateString('en-US', dateStringOptions);
  const timeString = date.toLocaleTimeString([], timeStringOptions);
  return `${dateString}${includeTime ? ` ${timeString}` : ''}`;
}

export const formatTimeDetail = (value: string, timeZone?: string): string => {
  return dateFormatter(
    new Date(value),
    {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
      timeZone,
      timeZoneName: 'short',
    },
    true
  );
};

export function getChangelogComments(changeLog: ChangeLog | null): CommentsData[] {
  if (!changeLog?.edges?.length) return [];

  // Filter out all non-comment changes from the audit list
  const changeLogEntries = changeLog.edges
    .map((edge: ChangeLogEntryEdge) => ({
      ...edge.node.audits,
      changeList: edge.node.audits?.changeList.filter(
        (change) => change.fieldName === 'comments'
      ) as SingleValueChange[],
    }))
    .filter((audit) => audit.changeList?.length);

  // Restructure to UI-friendly format
  return changeLogEntries.map((audit) => ({
    user: audit.modifiedBy || 'Unknown User',
    date: formatTimeDetail(audit.modifiedDate),
    comment: audit.changeList?.map((c) => c.newValue.payload).join(', '),
  }));
}
