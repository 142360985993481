import type { ValueOf } from '../../../../types/util';
import { ReviewStatus } from '../constants';
import type { StatusLabel } from '../types';
import { StageLabels } from '../types';
import { LabelType } from './LabelType';

const SuccessStatusList = [ReviewStatus.APPROVED, ReviewStatus.PUBLISHED];

const ErrorStatusList = [ReviewStatus.REJECTED];

export function getReviewLabel(status?: string | null): StatusLabel {
  if (!status || !Object.keys(ReviewStatus).includes(status)) return null;

  let type: ValueOf<typeof LabelType> = LabelType.IN_PROGRESS;

  if (SuccessStatusList.includes(status)) type = LabelType.SUCCESS;
  if (ErrorStatusList.includes(status)) type = LabelType.ERROR;

  return {
    label: ReviewStatus[status as keyof typeof ReviewStatus],
    type,
    stage: StageLabels.Review,
  };
}
