import type { AdFormValues } from '../../../../../common/AdForm/adFormik/types';
import { adTypeToCreativeType } from '../../../../../common/AdForm/adFormik/utils';
import type { CreativesBuilderValues } from '../../../../../common/AdForm/CreativeBuilders/types';

export function transformValuesToProperShapeForGraph(values: AdFormValues): Partial<CreativesBuilderValues> {
  const { creative, type } = values;

  const graphKeys: (keyof CreativesBuilderValues)[] = [
    'featureList',
    'id',
    'image',
    'logo',
    'name',
    'offerImage',
    'poster',
    'review',
    'slate',
    'template',
    'type',
    'video',
    'videoAssetList',
    'url',
    'vpaid',
    'adSourceId',
    'creativeLibraryId',
    'accent',
    'altText',
    'livingRoomCopy',
    'destinationUrl',
    'pushTitle',
    'pushBody',
    'emailSubject',
    'emailBody',
    'headline',
    'randomOrder',
    'voiceoverUrl',
    'optionList',
    'templateAssetList',
  ];

  // Create a new creative object using only the properties listed above
  const filteredCreative = Object.fromEntries(
    graphKeys.map((key) => [key, creative[key]])
  ) as Partial<CreativesBuilderValues>;

  return { ...filteredCreative, type: filteredCreative.type ?? adTypeToCreativeType(type) };
}
