import { SPReviewStatus, SPReviewStatusMap } from '../constants';
import type { StatusLabel } from '../types';
import { StageLabels } from '../types';
import { LabelType } from './LabelType';

export function getSPReviewLabel(status?: string | null): StatusLabel {
  if (!status || !Object.keys(SPReviewStatus).includes(status)) return null;

  return {
    label: SPReviewStatusMap[status as keyof typeof SPReviewStatus],
    type: status === SPReviewStatus.LOCKED ? LabelType.WARN : LabelType.SUCCESS,
    stage: StageLabels.SpReview,
  };
}
