import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, Observable } from '@apollo/client';
import type { MockedResponse } from '@apollo/client/testing';
import { MockedProvider } from '@apollo/client/testing';
import { MemoryRouter, Route, Routes } from '@hulu/react-router-dom';
import { Formik } from 'formik';
import type { InitialEntry } from 'history';
import React from 'react';

import introspectionResult from '../apis/graphql/introspection';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withMockedProvider = (
  WrappedComponent: React.JSX.Element | React.ReactNode,
  mocks?: MockedResponse[],
  wrapInRouter = true,
  initialEntries?: InitialEntry[]
): React.JSX.Element => {
  const cache = new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    addTypename: false,
  });

  return (
    <MockedProvider mocks={mocks} addTypename={false} cache={cache}>
      {wrapInRouter ? (
        <MemoryRouter initialEntries={initialEntries}>{WrappedComponent}</MemoryRouter>
      ) : (
        WrappedComponent
      )}
    </MockedProvider>
  );
};

const defaultCache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  addTypename: false,
});

export const MockProviderHook: React.FC<{ mocks: MockedResponse[]; cache?: InMemoryCache }> = ({
  mocks,
  children,
  cache = defaultCache,
}) => {
  return (
    <MockedProvider mocks={mocks} addTypename={false} cache={cache}>
      {children}
    </MockedProvider>
  );
};

// Apollo's provided MockedProvider doesn't allow us to fix the state as "loading"
export const LoadingMockedProvider: React.FunctionComponent = (props) => {
  const link = new ApolloLink(() => {
    return new Observable((): void => {});
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export const withFormikWrapper = (WrappedComponent: JSX.Element, initialValues: object): JSX.Element => (
  <Formik initialValues={initialValues} onSubmit={(): void => {}}>
    {WrappedComponent}
  </Formik>
);

// Reference: https://medium.com/@the_teacher/how-to-test-redirect-from-react-router-with-rtl-react-test-library-and-jest-242eced1c6b8
type ComponentWithRouterProps = {
  componentWithRedirection: () => JSX.Element;
  startingUrl: string;
  initialEntries?: InitialEntry[];
  redirectUrl: string;
};

export const ComponentWithRouter = ({
  componentWithRedirection,
  initialEntries,
  startingUrl,
  redirectUrl,
}: ComponentWithRouterProps): JSX.Element => {
  const resolvedInitialEntries: InitialEntry[] = initialEntries ? initialEntries : [startingUrl];

  return (
    <MemoryRouter initialEntries={resolvedInitialEntries}>
      <Routes>
        <Route path={startingUrl}>{componentWithRedirection()}</Route>
        <Route path={redirectUrl}>{<div>Redirecting to: {redirectUrl}</div>}</Route>
      </Routes>
    </MemoryRouter>
  );
};
