import './RejectionMessage.scss';

import { IconAlertErrorOutlined } from '@hulu-react-style-components/icons';
import { bem } from 'mission-control-common-components';
import type { ReactNode } from 'react';

type Props = {
  bodyText?: ReactNode;
  rejectionReason?: string | null;
};

const [block, element] = bem('rejection-message');

const RejectionMessage = ({ bodyText, rejectionReason }: Props): React.JSX.Element => {
  return (
    <div className={block()}>
      <IconAlertErrorOutlined className={element('error-svg')} />
      <div>
        {bodyText}
        {rejectionReason && (
          <div>
            Rejection Reason: <span className={element('rejection-reason')}>{rejectionReason}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RejectionMessage;
