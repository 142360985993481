import type { BaseAssetV5 } from '../../../../../apis/graphql';
import { TranscodeStatusV5 } from '../../../../../apis/graphql';

// The most restrictive transcoding status should go on top and the least restrictive one should go to the bottom of the list
export const TRANSCODING_STATUS_PRIORITY: TranscodeStatusV5[] = [
  TranscodeStatusV5.PreProcessingAbort,
  TranscodeStatusV5.Pending,
  TranscodeStatusV5.PreProcessingComplete,
  TranscodeStatusV5.Publishing,
  TranscodeStatusV5.Streamable,
];

export const setLatestTranscodingStatusForDxcCreative = (assets: BaseAssetV5[]): TranscodeStatusV5 | string => {
  const transcodeStatuses = assets.reduce<TranscodeStatusV5[]>((acc, asset) => {
    if (asset?.transcodeStatus) {
      acc.push(asset.transcodeStatus);
    }

    return acc;
  }, [] as TranscodeStatusV5[]);

  if (
    transcodeStatuses.length &&
    transcodeStatuses.length === assets.length &&
    transcodeStatuses.every((status) => status === TranscodeStatusV5.Streamable)
  ) {
    return TranscodeStatusV5.Streamable;
  }

  for (const mostRestrictiveTranscodingStatus of TRANSCODING_STATUS_PRIORITY) {
    if (transcodeStatuses.includes(mostRestrictiveTranscodingStatus)) {
      return mostRestrictiveTranscodingStatus;
    }
  }

  return '';
};
