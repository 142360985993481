import { useFormikContext } from 'formik';
import { User } from 'mission-control-common-components';
import React, { useEffect } from 'react';

import type { GetCreativeByIdQuery } from '../../../apis/graphql';
import { CreativeV5Type } from '../../../apis/graphql';
import type { AdType } from '../../../configs';
import type { PermissionsNames } from '../../../constants';
import bem from '../../../utils/bem';
import { useTrackFormFieldChanges } from '../../hooks/useTrackFormFieldChanges';
import type { AdFormValues } from '../adFormik';
import { AdFormFields } from '../adFormik/fields';
import {
  AdSelectorSlateCreativeBuilder,
  AdSelectorVideoCreativeBuilder,
  BrightlineCreativeBuilder,
  GatewayGoCreativeBuilder,
  InnovidCreativeBuilder,
  PauseCreativeBuilder,
  SlateCreativeBuilder,
  VideoCreativeBuilder,
} from '../CreativeBuilders';
import type { CreativeBuilderProps } from '../CreativeBuilders/CreativeBuilder/CreativeBuilder';
import { adProductDimensionSpec } from '../CreativeBuilders/dimensionsConfig';
import useCreativeId from '../CreativeBuilders/hooks/useCreativeId';
import type { CreativesBuilderValues } from '../CreativeBuilders/types';
import { CreativeEditMode } from '../CreativeBuilders/types';
import { wrapWithDxcCreativeBuilder } from '../CreativeBuilders/utils/wrapWithDxcCreativeBuilder';
import CreativeModeSelector from '../CreativeModeSelector';
import CreativeSearcher from '../CreativeSearcher';
import type { AdsPageLineItemType } from '../types';
import { typeIsVast } from '../utils';

const [block] = bem('creative');

const creativeBuilderByAdType: Record<AdType, (props: CreativeBuilderProps) => JSX.Element> = {
  UNKNOWN: VideoCreativeBuilder,
  AD_SELECTOR_SLATE: AdSelectorSlateCreativeBuilder,
  AD_SELECTOR_VIDEO: AdSelectorVideoCreativeBuilder,
  AD_SELECTOR: BrightlineCreativeBuilder,
  BRIGHTLINE: BrightlineCreativeBuilder,
  COLLECTION_LOGO: BrightlineCreativeBuilder,
  COVER_STORY_LOGO: BrightlineCreativeBuilder,
  GATEWAY_GO: wrapWithDxcCreativeBuilder(GatewayGoCreativeBuilder),
  GATEWAY_SHOP: BrightlineCreativeBuilder,
  HUB_LOGO: BrightlineCreativeBuilder,
  INNOVID: InnovidCreativeBuilder,
  MARQUEE_PROMOTED_CONTENT: BrightlineCreativeBuilder,
  MAX_SELECTOR: BrightlineCreativeBuilder,
  PAUSE: PauseCreativeBuilder,
  PAUSE_QR: PauseCreativeBuilder,
  SLATE: SlateCreativeBuilder,
  VIDEO: VideoCreativeBuilder,
  BE_SELECTOR_SLATE: SlateCreativeBuilder,
  BE_SELECTOR_VIDEO: VideoCreativeBuilder,
};

type Props = {
  creativeData: GetCreativeByIdQuery | undefined;
  loadingCreativeData: boolean;
  adType: AdType;
  lineItemType: AdsPageLineItemType;
  editMode: CreativeEditMode;
  isExistingAd?: boolean;
  isAdApproved?: boolean;
};

const CreativeBuilderRenderer = ({
  creativeData,
  loadingCreativeData,
  adType,
  lineItemType,
  editMode,
  isExistingAd,
  isAdApproved,
}: Props): JSX.Element => {
  const adTypeDimensions = adProductDimensionSpec[adType];
  const isInstreamDxc = creativeData?.getCreativeByIDV5.type === CreativeV5Type.InstreamDxc;

  const CreativeBuilder = creativeBuilderByAdType[adType];
  // Non-Vast creatives show the vast preview only if the Ad has been saved
  // Vast creatives show the vast preview always, regardless of whether the ad has been saved
  const isCreativeEditingDisabled =
    (typeIsVast(creativeData?.getCreativeByIDV5.type) ? !!creativeData : !!creativeData && isExistingAd) ||
    isInstreamDxc;
  const permissions = User.getPermissions<PermissionsNames>();
  const editModePermissionCheck = !permissions?.updateCreative ? CreativeEditMode.readonly : editMode;

  return (
    <CreativeBuilder
      isPreview={isCreativeEditingDisabled}
      adTypeDimensions={adTypeDimensions}
      lineItemType={lineItemType}
      creativeData={creativeData}
      editMode={editModePermissionCheck}
      isAdApproved={isAdApproved}
      loadingCreativeData={loadingCreativeData}
    />
  );
};

type CardContentsByModeProps = {
  mode: CreativesBuilderValues['mode'];
  creativeData: GetCreativeByIdQuery | undefined;
  loadingCreativeData: boolean;
  type: AdType;
  lineItemType: AdsPageLineItemType;
  editMode: CreativeEditMode;
  isExistingAd?: boolean;
  isAdApproved?: boolean;
};

const cardContentsByMode = ({
  mode,
  creativeData,
  loadingCreativeData,
  type,
  lineItemType,
  editMode,
  isAdApproved,
  isExistingAd,
}: CardContentsByModeProps): React.JSX.Element => {
  switch (mode) {
    case 'selectOption':
      return <CreativeModeSelector editMode={editMode} />;
    case 'search':
      return <CreativeSearcher lineItemType={lineItemType} />;
    default:
      return (
        <CreativeBuilderRenderer
          creativeData={creativeData}
          loadingCreativeData={loadingCreativeData}
          adType={type}
          lineItemType={lineItemType}
          isExistingAd={isExistingAd}
          editMode={editMode}
          isAdApproved={isAdApproved}
        />
      );
  }
};

interface CreativeProps {
  lineItemType: AdsPageLineItemType;
  editMode: CreativeEditMode;
  isAdApproved?: boolean;
}
const Creative = ({ lineItemType, editMode, isAdApproved }: CreativeProps): React.JSX.Element => {
  const { isCreativeChanged } = useTrackFormFieldChanges();
  const {
    setFieldValue,
    values: { id: adId, type, creative, creativeChanged },
  } = useFormikContext<AdFormValues>();

  useEffect(() => {
    if (creativeChanged !== isCreativeChanged) {
      setFieldValue(AdFormFields.CreativeChanged, isCreativeChanged);
    }
  }, [creativeChanged, isCreativeChanged, setFieldValue]);

  const { mode, id, type: creativeType, url, adSourceId } = creative;
  const { data: creativeData, loading: loadingCreativeData } = useCreativeId({ creativeId: creative?.id || id || '' });

  // VastLike includes Vast, Drax, Magnite, etc.
  const isVastLike = url && !adSourceId;

  useEffect(() => {
    // Creatives that do not have vast-style attributes can retain their existing type
    if (!isVastLike) return;

    // If the parent line item is DRAX, then we'll set the creative's type to DRAX by default
    if (lineItemType === 'DRAX' && creative.type !== 'DRAX') setFieldValue('creative', { ...creative, type: 'DRAX' });

    // If the creative has a Vast-Like structure but its type is not Vast, we'll set it to Vast by default
    if (!typeIsVast(creativeType)) setFieldValue('creative', { ...creative, type: 'VAST_VIDEO' });
  }, [creative, creativeType, lineItemType, isVastLike, setFieldValue]);

  return (
    <div className={block()}>
      {cardContentsByMode({
        mode,
        creativeData,
        type,
        lineItemType,
        editMode,
        isExistingAd: !!adId,
        isAdApproved,
        loadingCreativeData,
      })}
    </div>
  );
};

export default Creative;
