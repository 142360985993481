import type { TranscodeStatusV5 } from '../../../../../apis/graphql';
import { PillColor } from '../../../../../common/Pill';

export function getTranscodePillColor(transcodeStatus: TranscodeStatusV5): PillColor | null {
  switch (transcodeStatus) {
    case 'STREAMABLE':
      return PillColor.GREEN;
    case 'PRE_PROCESSING':
    case 'PRE_PROCESSING_COMPLETE':
      return PillColor.YELLOW;
    case 'PUBLISHING':
    case 'PUBLISHING_COMPLETE':
    case 'STREAMABLE_CHECKING':
      return PillColor.BLUE;
    case 'PRE_PROCESSING_ABORT':
    case 'PRE_PROCESSING_ERROR':
    case 'PUBLISHING_ABORT':
    case 'PUBLISHING_ERROR':
    case 'STREAMABLE_ABORT':
    case 'STREAMABLE_CHECKING_ERROR':
    case 'STREAMABLE_ERROR':
      return PillColor.RED;
    case 'PENDING':
      return PillColor.GRAY;
    default:
      return null;
  }
}
