import envConfig from '@hulu/env-config';
import { bem } from 'mission-control-common-components';
import React, { useMemo, useState } from 'react';

import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import { AssetTypeV5 } from '../../../../../apis/graphql';
import type { AssetV5, DisplayAssetV5, TextAssetV5 } from '../../../../../models';
import AssetPreview from '../../../../AssetPreview';
import type { AssetPreviewProps } from '../../../../AssetPreview/AssetPreview';
import { NO_DISPLAY_ASSETS_TEXT, NO_TEXT_ASSETS_TEXT, NO_VIDEO_ASSETS_TEXT } from '../constants';

export type InstreamDxcTabItem = {
  key: AssetTypeV5;
  title: string;
  element: React.JSX.Element;
  emptyMessage: string;
  tabIndex: number;
  itemProps: AssetPreviewProps[];
  previewElement: (props: AssetPreviewProps) => React.JSX.Element;
};

type UseInstreamDxcTabs = {
  selectedTab: AssetTypeV5;
  handleTabClick: (type: AssetTypeV5) => void;
  tabs: InstreamDxcTabItem[];
};

type UseInstreamDxcTabsProps = {
  displayAssets: DisplayAssetV5[];
  videoAssets: AssetExtended[];
  textAssets: TextAssetV5[];
  creativeId?: string;
};

const [, element] = bem('instream-dxc-builder');

export const useInstreamDxcTabs = ({
  displayAssets,
  videoAssets,
  textAssets,
  creativeId,
}: UseInstreamDxcTabsProps): UseInstreamDxcTabs => {
  const [selectedTab, setSelectedTab] = useState<AssetTypeV5>(AssetTypeV5.Video);

  const isTextAssetEnabled = envConfig.REACT_APP_FEATURE_ENABLE_TEXT_ASSET === 'true';

  const tabs = useMemo((): InstreamDxcTabItem[] => {
    return [
      {
        key: AssetTypeV5.Video,
        title: AssetTypeV5.Video,
        element: (
          <span className={element('tab-title')}>
            {`${AssetTypeV5.Video}`}
            <span className={element('tab-counter')}>{` (${videoAssets.length})`}</span>
          </span>
        ),
        emptyMessage: NO_VIDEO_ASSETS_TEXT,
        tabIndex: 0,
        itemProps: videoAssets.map((asset): AssetPreviewProps => ({ asset: asset as AssetV5, creativeId })),
        previewElement: AssetPreview,
      },
      {
        key: AssetTypeV5.Display,
        title: AssetTypeV5.Display,
        element: (
          <span className={element('tab-title')}>
            {`${AssetTypeV5.Display}`}
            <span className={element('tab-counter')}>{` (${displayAssets.length})`}</span>
          </span>
        ),
        emptyMessage: NO_DISPLAY_ASSETS_TEXT,
        tabIndex: 1,
        itemProps: displayAssets.map((asset) => ({ asset, creativeId })),
        previewElement: AssetPreview,
      },
      ...(isTextAssetEnabled
        ? [
            {
              key: AssetTypeV5.Text,
              title: AssetTypeV5.Text,
              element: (
                <span className={element('tab-title')}>
                  {`${AssetTypeV5.Text}`}
                  <span className={element('tab-counter')}>{` (${textAssets.length})`}</span>
                </span>
              ),
              emptyMessage: NO_TEXT_ASSETS_TEXT,
              tabIndex: 2,
              itemProps: textAssets.map((asset) => ({ asset, creativeId })),
              previewElement: AssetPreview,
            },
          ]
        : []),
    ];
  }, [creativeId, displayAssets, videoAssets, textAssets, isTextAssetEnabled]);

  const handleTabClick = (type: AssetTypeV5): void => {
    setSelectedTab(type);
  };

  return {
    selectedTab,
    handleTabClick,
    tabs,
  };
};
