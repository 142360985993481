// APS = Ad Platform Solutions, our front-line support for user issues
import type { RequiredPermissions } from 'mission-control-common-components';

import { PillColor } from './common/Pill';
import type { LineItemDerivedState } from './configs';

export const APS_TICKET_LINK = 'https://jira.hulu.com/secure/CreateIssueDetails!init.jspa?pid=23291';

export const MISSION_CONTROL_SUPPORT_EMAIL = 'mission-control-support@hulu.com';

export const JIRA_TICKET_LINK =
  'https://ad-platform-support-intake.app.digital.disneyadvertising.com/AOSSupport/AOSSupportRioMC/AOSSupportRioPlatformBugs';

export enum PathName {
  ads = '/ads',
  adsCreate = '/adsCreate',
  adsEdit = '/adsEdit',
  assetReview = '/asset-review',
  create = '/create',
  campaignReview = '/campaign-review',
  forbidden = '/forbidden',
  reports = '/reports',
  serviceDown = '/serviceDown',
  trafficking = '/trafficking',
  inventory = '/inventory',
  adLoadManager = '/inventory/alm/rules',
  inventoryRestrictionManager = '/inventory/irm/rules',
  accessManager = '/access-manager',
  oauthCallback = '/oauth/callback',
  pricing = '/pricing',
  supplyManagement = '/supply-management',
  demandManagement = '/demand-management',
  targetingPreset = '/targeting-preset',
  inventoryBlock = '/inventory-block',
  mcportalStaging = `/mcportal-staging/*`,
  sequence = '/sequence',
}

export const DEFAULT_TIMEZONE = 'America/New_York';

export enum TraffickingTableName {
  campaigns = 'campaigns',
  lineItems = 'lineItems',
  ads = 'ads',
}

export const TRAFFICKING_TABLE_NAMES: TraffickingTableName[] = [
  TraffickingTableName.campaigns,
  TraffickingTableName.lineItems,
  TraffickingTableName.ads,
];

export enum SearchParams {
  ShareableId = 'shareableId',
}

export const TRAFFICKING_SEARCH_PARAMS = {
  ACCOUNT_EXECUTIVE: 'accountExecutive',
  ACCOUNT_MANAGER: 'accountManager',
  AD_RATING_LIST: 'adRatingList',
  AD_STATUS_LIST: 'adStatusList',
  AD_TYPE_LIST: 'adTypeList',
  ADS_ITEMS_SELECTED_IDS: 'adsSelectedIds',
  AGENCY_ID_LIST: 'agencyIdList',
  BILLABLE_THIRD_PARTY_LIST: 'billableThirdPartyList',
  BRAND_ID_LIST: 'brandIdList',
  COUNTRY_LIST: 'countryList',
  CREATIVE_REVIEW_LIST: 'creativeReviewList',
  CREATIVE_TYPE_LIST: 'creativeTypeList',
  CURRENCY_LIST: 'currencyList',
  DATE_FILTER: 'dateFilter',
  HAS_MISALIGNED_SCHEDULE: 'hasMisalignedSchedule',
  INDUSTRY_ID_LIST: 'industryIdList',
  IS_ADDED_VALUE: 'isAddedValue',
  IS_COPPA_OR_CARRU: 'isCoppaOrCaru',
  IS_MAKEGOOD: 'isMakegood',
  IS_ROTATION: 'isRotation',
  IS_SEQUENCE: 'isSequence',
  LINE_ITEM_STATUS_LIST: 'lineItemStatusList',
  LINE_ITEMS_SELECTED_IDS: 'lineItemsSelectedIds',
  ORDER_TYPE_LIST: 'orderTypeList',
  PACING_RISK_LIST: 'pacingRiskList',
  GENERAL_PACING_STATUS_LIST: 'generalPacingStatusList',
  PRIORITY_VALUE_LIST: 'priorityValueList',
  PRODUCT_TYPE: 'productType',
  PUBLISHER_LIST: 'publisherList',
  REVIEW_STATUS_LIST: 'reviewStatusList',
  SELECTED_TAB: 'selectedTab',
  TIER_NAME_LIST: 'tierNameList',
} as const;

export type SearchParamsValuesType = typeof TRAFFICKING_SEARCH_PARAMS[keyof Omit<
  typeof TRAFFICKING_SEARCH_PARAMS,
  'LINE_ITEMS_SELECTED_IDS' | 'ADS_ITEMS_SELECTED_IDS'
>];

export enum ERROR_PATTERNS {
  STATUS_ACTIVATION_ERROR = 'Status activation error',
  TIER_NAME_ERROR = 'Tier[^]+priority|LineItem Type cannot have a LineItemTier Rank',
  PRIORITY_NAME_ERROR = 'Tier[^]+priority',
}

export const derivedStateColorsMap: Record<LineItemDerivedState, PillColor> = {
  LIVE: PillColor.GREEN,
  READY: PillColor.GREEN,
  LATE: PillColor.YELLOW,
  ENDED: PillColor.GRAY,
  DID_NOT_RUN: PillColor.YELLOW,
};

export enum PermissionsNames {
  supplyManagementPageRead = 'supplyManagementPageRead',
  pricingPageRead = 'pricingPageRead',
  traffickingPageRead = 'traffickingPageRead',
  campaignReviewPageRead = 'campaignReviewPageRead',
  assetReviewPageRead = 'assetReviewPageRead',
  inventoryPageRead = 'inventoryPageRead',
  presetPageRead = 'presetPageRead',
  accessManagerPageRead = 'accessManagerPageRead',
  demandManagementPageRead = 'demandManagementPageRead',

  /// trafficking
  readTraffickingCampaigns = 'readTraffickingCampaigns',
  readTraffickingChangeLog = 'readTraffickingChangeLog',
  readTraffickingLineItems = 'readTraffickingLineItems',
  readTraffickingAds = 'readTraffickingAds',

  patchCampaign = 'patchCampaign',

  createAd = 'createAd',
  updateLineItem = 'updateLineItem',
  updateLineItemPacingType = 'updateLineItemPacingType',
  updateAd = 'updateAd',
  deleteAd = 'deleteAd',
  duplicateAd = 'duplicateAd',

  readRotation = 'readRotation',
  createRotation = 'createRotation',
  deleteRotation = 'deleteRotation',
  updateRotation = 'updateRotation',

  readCreative = 'readCreative',
  createCreative = 'createCreative',
  updateCreative = 'updateCreative',

  createCampaignReviewLink = 'createCampaignReviewLink',
  createLineItemReviewLink = 'createLineItemReviewLink',

  overrideLineItemReview = 'overrideLineItemReview',
  overrideAdReview = 'overrideAdReview',

  setErrors = 'setErrors',

  createInventoryBlock = 'createInventoryBlock',
  readInventoryBlock = 'readInventoryBlock',
  updateInventoryBlock = 'updateInventoryBlock',
  deleteInventoryBlock = 'deleteInventoryBlock',
}

export const requiredPermissions: RequiredPermissions<PermissionsNames> = {
  [PermissionsNames.traffickingPageRead]: {
    name: ['SUPPORT_ADMIN', 'TRAFFICKER_US', 'TRAFFICKER_LATAM', 'ANALYST', 'DRAX_OPS_MANAGER', 'DRAX_ADMIN'],
    permissions: [
      {
        name: 'READ_ALL_CAMPAIGNS',
        'service-namespace': 'adscampaignsearcher',
      },
      {
        name: 'READ_ALL_LINE_ITEMS',
        'service-namespace': 'adscampaignsearcher',
      },
      {
        name: 'READ_ALL_ADS',
        'service-namespace': 'adscampaignsearcher',
      },
    ],
  },
  [PermissionsNames.campaignReviewPageRead]: {
    name: ['SUPPORT_ADMIN', 'TRAFFICKER_US', 'TRAFFICKER_LATAM', 'ANALYST', 'DRAX_ADMIN', 'DRAX_OPS_MANAGER'],
    permissions: [
      {
        name: 'READ_ALL_CAMPAIGNS',
        'service-namespace': 'adscampaignsearcher',
      },
      {
        name: 'READ_ALL_LINE_ITEMS',
        'service-namespace': 'adscampaignsearcher',
      },
      {
        name: 'READ_ALL_ADS',
        'service-namespace': 'adscampaignsearcher',
      },
    ],
  },
  [PermissionsNames.assetReviewPageRead]: {
    permissions: [
      {
        name: 'READ_ALL_REVIEW_TASKS',
        'service-namespace': 'adscampaignsearcher',
      },
    ],
  },
  [PermissionsNames.inventoryPageRead]: {
    permissions: [
      {
        name: 'READ_AD_LOAD',
        'service-namespace': 'inventorymanager',
      },
      {
        name: 'READ_RESTRICTION',
        'service-namespace': 'inventorymanager',
      },
    ],
  },
  [PermissionsNames.presetPageRead]: {
    permissions: [
      {
        name: 'READ_PRESET',
        'service-namespace': 'targetmanager',
      },
    ],
  },
  [PermissionsNames.accessManagerPageRead]: {
    name: [
      'ANALYST',
      'TRAFFICKER_US',
      'TRAFFICKER_LATAM',
      'SUPPORT_ADMIN',
      'DRAX_ADMIN',
      'ACCESS_MANAGER',
      'CREATIVE_REVIEWER',
      'DRAX_OPS_MANAGER',
      'CREATIVE_ADMIN_REVIEWER',
    ],
    permissions: [
      {
        name: 'READ_ROLES',
        'service-namespace': 'accountmanager',
      },
      {
        name: 'READ_PUBLISHER_USER',
        'service-namespace': 'accountmanager',
      },
    ],
  },
  [PermissionsNames.pricingPageRead]: {
    permissions: [
      {
        name: 'READ_RATE_CARD',
        'service-namespace': 'pricingmanager',
      },
      {
        name: 'READ_MANAGED_GROUP',
        'service-namespace': 'pricingmanager',
      },
      {
        name: 'READ_ORGANIZATION_RATE_CARD',
        'service-namespace': 'pricingmanager',
      },
      {
        name: 'READ_CHANGE_LOG',
        'service-namespace': 'pricingmanager',
      },
    ],
  },
  [PermissionsNames.supplyManagementPageRead]: {
    permissions: [
      {
        name: 'READ',
        'service-namespace': 'supplymanager',
      },
    ],
  },
  [PermissionsNames.readTraffickingCampaigns]: {
    permissions: [
      {
        name: 'READ_ALL_CAMPAIGNS',
        'service-namespace': 'adscampaignsearcher',
      },
    ],
  },
  [PermissionsNames.readTraffickingChangeLog]: {
    permissions: [
      {
        name: 'READ_AUDITING',
        'service-namespace': 'auditingmanager',
      },
    ],
  },
  [PermissionsNames.readTraffickingLineItems]: {
    permissions: [
      {
        name: 'READ_ALL_LINE_ITEMS',
        'service-namespace': 'adscampaignsearcher',
      },
    ],
  },
  [PermissionsNames.readTraffickingAds]: {
    permissions: [
      {
        name: 'READ_ALL_ADS',
        'service-namespace': 'adscampaignsearcher',
      },
    ],
  },
  [PermissionsNames.createAd]: {
    permissions: [
      {
        name: 'CREATE_AD',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },

  [PermissionsNames.updateLineItem]: {
    permissions: [
      {
        name: 'UPDATE_LINEITEM',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },
  [PermissionsNames.updateLineItemPacingType]: {
    permissions: [
      {
        name: 'UPDATE_PACING_TYPE',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },
  [PermissionsNames.updateAd]: {
    permissions: [
      {
        name: 'UPDATE_AD',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },
  [PermissionsNames.deleteAd]: {
    permissions: [
      {
        name: 'DELETE_AD',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },
  [PermissionsNames.readRotation]: {
    permissions: [
      {
        name: 'READ_ROTATION',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },

  [PermissionsNames.createRotation]: {
    permissions: [
      {
        name: 'CREATE_ROTATION',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },

  [PermissionsNames.deleteRotation]: {
    permissions: [
      {
        name: 'DELETE_ROTATION',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },

  [PermissionsNames.updateRotation]: {
    permissions: [
      {
        name: 'PATCH_ROTATION',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },

  [PermissionsNames.patchCampaign]: {
    permissions: [
      {
        name: 'PATCH_CAMPAIGN',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },
  [PermissionsNames.readCreative]: {
    permissions: [
      {
        name: 'READ_CREATIVE',
        'service-namespace': 'haapiv2creativemanager',
      },
    ],
  },

  [PermissionsNames.createCreative]: {
    permissions: [
      {
        name: 'CREATE_CREATIVE',
        'service-namespace': 'haapiv2creativemanager',
      },
    ],
  },

  [PermissionsNames.updateCreative]: {
    permissions: [
      {
        name: 'PATCH_CREATIVE',
        'service-namespace': 'haapiv2creativemanager',
      },
      {
        name: 'UPDATE_CREATIVE',
        'service-namespace': 'haapiv2creativemanager',
      },
    ],
  },

  [PermissionsNames.createCampaignReviewLink]: {
    permissions: [
      {
        name: 'PATCH_CAMPAIGN_LINEITEMS',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },

  [PermissionsNames.createLineItemReviewLink]: {
    permissions: [
      {
        name: 'PATCH_LINEITEM',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },
  [PermissionsNames.demandManagementPageRead]: {
    permissions: [
      {
        name: 'READ',
        'service-namespace': 'demandmanager',
      },
    ],
  },
  [PermissionsNames.overrideLineItemReview]: {
    name: ['SUPPORT_ADMIN'],
    permissions: [
      {
        name: 'UPDATE_LINEITEM',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },
  [PermissionsNames.overrideAdReview]: {
    name: ['SUPPORT_ADMIN'],
    permissions: [
      {
        name: 'UPDATE_AD',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },
  [PermissionsNames.duplicateAd]: {
    permissions: [
      {
        name: 'DUPLICATE_AD',
        'service-namespace': 'haapiv2campaignmanager',
      },
    ],
  },
  [PermissionsNames.setErrors]: {
    permissions: [
      { 'service-namespace': 'haapiv2campaignmanager', name: 'DELETE_REVIEW_ERROR' },
      { 'service-namespace': 'haapiv2campaignmanager', name: 'CREATE_REVIEW_ERROR' },
    ],
  },
  [PermissionsNames.createInventoryBlock]: {
    permissions: [{ 'service-namespace': 'inventorymanager', name: 'CREATE_INVENTORY_BLOCK' }],
  },
  [PermissionsNames.readInventoryBlock]: {
    permissions: [{ 'service-namespace': 'inventorymanager', name: 'READ_INVENTORY_BLOCK' }],
  },
  [PermissionsNames.updateInventoryBlock]: {
    permissions: [{ 'service-namespace': 'inventorymanager', name: 'UPDATE_INVENTORY_BLOCK' }],
  },
  [PermissionsNames.deleteInventoryBlock]: {
    permissions: [{ 'service-namespace': 'inventorymanager', name: 'DELETE_INVENTORY_BLOCK' }],
  },
};

export const CAMPAIGNS_KEY_FIELDS = [
  'filterOptions',
  [
    'idList',
    'selectedIdList',
    'traffickerEmailList',
    'accountExecutiveList',
    'schedule',
    'searchTerm',
    'adProductList',
    'pacingRiskList',
    'generalPacingStatusList',
    'reviewStatusList',
    'orderTypeList',
    'adStatusList',
    'lineItemStatusList',
    'lineItemSubStatusList',
    'hasMisalignedSchedule',
    'tierNameList',
    'priorityValueList',
    'billableThirdPartyList',
    'publisherList',
    'adRatingList',
    'adTypeList',
    'creativeTypeList',
    'creativeReviewList',
    'isCoppaOrCaru',
    'isMakegood',
    'isAddedValue',
    'brandIdList',
    'agencyIdList',
    'industryIdList',
    'currencyList',
    'isRotation',
    'isSequence',
    'countryList',
  ],
  'sortOptions',
  ['sort', 'order'],
];

export const LINE_ITEMS_KEY_FIELDS = [
  CAMPAIGNS_KEY_FIELDS[0],
  [...CAMPAIGNS_KEY_FIELDS[1], 'campaignIdList', 'statusList', 'subStatusList', 'hasMisalignedSchedule'],
  CAMPAIGNS_KEY_FIELDS[2],
  CAMPAIGNS_KEY_FIELDS[3],
];

export const ADS_KEY_FIELDS = [
  CAMPAIGNS_KEY_FIELDS[0],
  [
    ...CAMPAIGNS_KEY_FIELDS[1],
    'campaignIdList',
    'lineItemIdList',
    'hasMisalignedSchedule',
    'lineItemParentLineOmsLinkList',
    'creativeIdList',
  ],
  CAMPAIGNS_KEY_FIELDS[2],
  CAMPAIGNS_KEY_FIELDS[3],
];

export const generalErrorMessage = 'Internal server error.';

export const invalidParameterErrorMessage = 'INVALID PARAMETER PROVIDED';

export const generalErrorDescription = (
  <span>
    Something went wrong. Please try again in a few minutes or email us at
    <br />
    <a href={`mailto:${MISSION_CONTROL_SUPPORT_EMAIL}`}>{MISSION_CONTROL_SUPPORT_EMAIL}</a>
  </span>
);

export const TRAFFICKER_EMAILS_KEY_FIELDS = [];

export const TARGETING_VALUES_KEY_FIELD = ['categoryGuid', 'dimensionGuid'];

export const WARNING_ALERT_MESSAGES = {
  ACTIONS_REQUIRED: 'Additional actions required',
};

export const WARNING_ALERT_DESCRIPTIONS = {
  STATUS_REVERTED: 'Ad status reverted to OFF and Under Review. Please resubmit ad for Campaign Review',
};

export const SEARCH_CREATIVES_KEY_FIELDS = ['filterOptions', ['searchTerm', 'type', 'idList']];

export const COLUMN_NAME_ID = 'Name';
