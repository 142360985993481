import './ErrorMessage.scss';

import { IconAlertErrorRed } from '@hulu-react-style-components/icons';
import React from 'react';

import { JIRA_TICKET_LINK } from '../../constants';
import bem from '../../utils/bem';

type ErrorMessageProps = {
  message?: string;
  showEmailAddress?: boolean;
};

const [block, element] = bem('error-message');

const ErrorMessage = ({ message, showEmailAddress }: ErrorMessageProps): JSX.Element => {
  return (
    <div className={block()}>
      <div className={element('content')}>
        <div className={element('icon')}>
          <IconAlertErrorRed />
        </div>
        <div className={element('text-container')}>
          {showEmailAddress && (
            <h4 className={element('header-text')}>
              Sorry, something went wrong. Please try again and escalate to AOS via a{' '}
              <a className={element('email-link')} target={`_blank`} href={JIRA_TICKET_LINK}>
                Jira Ticket
              </a>{' '}
              if the issue persists.
            </h4>
          )}
          {message ? (
            <p className={element('message-text')}>
              <span className={element('bold')}>Error details: </span>
              {message}
            </p>
          ) : (
            <p className={element('message-text')}>Error</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
