import { PillColor } from './Pill';

export const TRANSCODE_PILL_COLORS: { [key: string]: PillColor } = {
  PENDING: PillColor.GRAY,
  QC_PENDING: PillColor.GRAY,
  PREVIEW: PillColor.GRAY,
  QC_PREVIEW: PillColor.GRAY,
  REVIEW: PillColor.GRAY,
  QC_REVIEW: PillColor.GRAY,
  PRE_PROCESSING: PillColor.YELLOW,
  PRE_PROCESSING_COMPLETE: PillColor.YELLOW,
  PRE_PROCESSING_ERROR: PillColor.RED,
  PRE_PROCESSING_ABORT: PillColor.RED,
  PUBLISHING: PillColor.BLUE,
  PUBLISHING_COMPLETE: PillColor.BLUE,
  PUBLISHING_ERROR: PillColor.RED,
  PUBLISHING_ABORT: PillColor.RED,
  STREAMABLE: PillColor.GREEN,
  STREAMABLE_ABORT: PillColor.RED,
  STREAMABLE_CHECKING: PillColor.BLUE,
  STREAMABLE_CHECKING_ERROR: PillColor.RED,
  STREAMABLE_CHECKING_ABORT: PillColor.RED,
  STREAMABLE_ERROR: PillColor.RED,
};
