import styled from 'styled-components';

import { theme } from '../../../../styles/styled';
import { gridPx } from '../../../../styles/styled/constants';

export const VideoFigure = styled.figure`
  display: table;
  position: relative;
  width: 100%;
  margin: 0;
  background-color: ${theme.color.design.gray6};
`;

export const VideoContainer = styled.div`
  position: relative;

  & video {
    max-width: 194px;
    height: 109px;
    background-color: black;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  span {
    img {
      display: block;
      margin: ${gridPx(4.375)} auto;
      height: ${gridPx(4.375)};
    }
  }
`;
