import './FrequencyCaps.scss';

import { IconCircleMinus, IconCirclePlus } from '@hulu-react-style-components/icons';
import { TextField as TrekTextField } from '@hulu/react-style-components';
import React from 'react';

import type { FrequencyCapDurationUnitV5 } from '../../apis/graphql';
import type { DisplayEnum, FrequencyCapUnitType } from '../../configs';
import { UNRECOGNIZED } from '../../configs';
import { frequencyCapUnitTypes } from '../../configs/frequencyCapUnitTypes';
import type { FrequencyCap, FrequencyCapInput, Nullable } from '../../models';
import bem from '../../utils/bem';
import type { ListHelpers } from '../../utils/listHelpers';
import type { DropdownOption } from '../Dropdown';
import type { SingleSelectValue } from '../Dropdown/Dropdown';
import Dropdown from '../Dropdown/Dropdown';
import { configToDropdownOption } from '../Dropdown/utils';
import type { FrequencyCapErrorObject } from '../EditableTableCells/EditableFrequencyCap/EditableFrequencyCap';

// limit and count are clearable within the <input /> components
export interface ClearableFrequencyCap extends Pick<FrequencyCap, 'type' | 'durationUnit'> {
  limit: Nullable<number> | Nullable<string>;
  duration: Nullable<number> | Nullable<string>;
  enabled: Nullable<boolean>;
}

export interface FrequencyCapProps {
  frequencyCapList: ClearableFrequencyCap[];
  frequencyCapType: FrequencyCap['type'];
  listHelpers: Pick<ListHelpers<ClearableFrequencyCap>, 'insertAt' | 'removeAt' | 'replaceAt'>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  setIsDropdownOpen?: (isOpen: boolean) => void; // give the rendering component the ability to track dropdown status
  validationResult?: FrequencyCapErrorObject;
  isClearable: boolean; // is final frequency cap row removable
  readonly?: boolean;
}

const [block, element] = bem('frequency-caps');

export const getDefaultFrequencyCap = (type: FrequencyCapInput['type']): FrequencyCapInput => ({
  type,
  limit: 2,
  duration: 45,
  durationUnit: frequencyCapUnitTypes['MINUTE'] as DisplayEnum<FrequencyCapDurationUnitV5>,
  enabled: true,
});

// Get string value for input elements
const getInputValue = (value: ClearableFrequencyCap['limit'] | ClearableFrequencyCap['duration']): string =>
  !value ? '' : value.toString();

function FrequencyCaps({
  frequencyCapList,
  frequencyCapType,
  onKeyDown,
  listHelpers: { insertAt, removeAt, replaceAt },
  setIsDropdownOpen,
  validationResult,
  isClearable,
  readonly = false,
}: FrequencyCapProps): JSX.Element {
  const defaultFrequencyCap: FrequencyCapInput = getDefaultFrequencyCap(frequencyCapType);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: 'limit' | 'duration'
  ): void =>
    replaceAt(index, {
      ...frequencyCapList[index],
      [field]: e.target.value ? parseInt(e.target.value) : null,
    });

  const handleUnitChange = (newUnit: FrequencyCapUnitType, index: number): void => {
    replaceAt(index, {
      ...frequencyCapList[index],
      durationUnit: frequencyCapUnitTypes[newUnit],
    });

    setIsDropdownOpen && setIsDropdownOpen(false);
  };
  const unitDropdownOptions: DropdownOption<FrequencyCapUnitType>[] = Object.values(frequencyCapUnitTypes)
    // TODO: removed from MC Alpha phase
    .filter((fC): boolean => fC !== frequencyCapUnitTypes['UNKNOWN'] && fC !== frequencyCapUnitTypes['LIFETIME'])
    .map(configToDropdownOption);

  return (
    <div className={block()}>
      {!frequencyCapList.length && (
        <div className={element('fc-row', 'empty')}>
          <p>No current frequency caps.</p>
          <div className="row-btns">
            <button aria-label="add row" onClick={(): void => insertAt(0, defaultFrequencyCap)}>
              <IconCirclePlus />
            </button>
          </div>
        </div>
      )}
      {frequencyCapList &&
        frequencyCapList.map((item: ClearableFrequencyCap, index: number) => {
          const label = index === 0 ? 'Unit' : '';
          const selectedDropdown = { value: item?.durationUnit?.key, label: item?.durationUnit?.displayName };
          const onChange = (selectedOption: SingleSelectValue<FrequencyCapUnitType | typeof UNRECOGNIZED>): void => {
            const selectedOptionValue: FrequencyCapUnitType | null =
              selectedOption && selectedOption.value !== UNRECOGNIZED ? selectedOption.value : null;
            if (selectedOptionValue) {
              handleUnitChange(selectedOptionValue, index);
            }
          };

          return (
            <div className={element('fc-row')} key={index}>
              <TrekTextField
                className={element('item')}
                isErr={validationResult?.limitErrorPositions.includes(index)}
                label="Limit"
                value={getInputValue(item.limit)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleInputChange(e, index, 'limit')}
                onKeyDown={onKeyDown}
                type="number"
                min="1"
                disabled={readonly}
              />
              <span className="input-divider">/</span>
              <TrekTextField
                className={element('item')}
                isErr={validationResult?.countErrorPositions.includes(index)}
                label="Count"
                value={getInputValue(item.duration)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleInputChange(e, index, 'duration')}
                onKeyDown={onKeyDown}
                type="number"
                min="1"
                disabled={readonly}
              />
              <Dropdown<FrequencyCapUnitType | typeof UNRECOGNIZED>
                classNameModifier={`dropdown ${element('item')}`}
                label={label}
                options={unitDropdownOptions}
                value={selectedDropdown}
                onChange={onChange}
                tabIndex={'-1'}
                isSearchable={false}
                isDisabled={readonly}
                dataTestId="unit-dropdown"
              />
              <div className="row-btns">
                <button
                  aria-label="remove row"
                  onClick={(): void => removeAt(index)}
                  disabled={(!isClearable && frequencyCapList.length === 1) || readonly}
                >
                  <IconCircleMinus />
                </button>
                <button
                  aria-label="add row"
                  onClick={(): void => insertAt(index + 1, defaultFrequencyCap)}
                  disabled={readonly}
                >
                  <IconCirclePlus />
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default FrequencyCaps;
