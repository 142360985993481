import { bem } from 'mission-control-common-components';
import React from 'react';

import { TranscodeStatus } from '../../../../_ForCommonLibrary/components/atoms/AssetStatusPill/constants';
import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import type { IngestionJobStatus } from '../../../../../apis/graphql';
import { SpReviewV5 } from '../../../../../apis/graphql';
import type { CreativeReviewStatus } from '../../../../../configs';
import Tooltip from '../../../../Tooltip';
import CreativeReviewBadge from '../../../CreativeReviewBadge';
import { ingestButton } from '../../configs';
import { reviewAlertPropsMap } from '../../utils';
import { AssetIngestButton } from '../AssetIngestButton';
import { CreativeFooterStyling } from '../CreativeFooterStyling';
import { IngestPill } from '../IngestPill';
import { SetAssetErrorButton } from '../SetAssetErrorButton';

const element = bem('creative-footer')[1];

export const AssetIngestionFooter: React.FC<{
  assets?: AssetExtended[] | null;
  hasIngestError?: boolean;
  ingestionJobStatus: IngestionJobStatus;
  readonly?: boolean;
  creativeReview?: CreativeReviewStatus;
}> = ({ assets, hasIngestError, ingestionJobStatus, readonly, creativeReview }) => {
  const hasTranscodeAbort = assets?.find((a) => a.transcodeStatus === TranscodeStatus.PUBLISHING_ABORT);
  const reviewAlertProps = creativeReview ? reviewAlertPropsMap?.[creativeReview] : null;
  const allValuesLocked = assets
    ?.map((asset) => asset.reviewTask?.spReview)
    .every((value): boolean => value === SpReviewV5.Locked);

  const { tooltipConfig, tooltipText } = ingestButton;

  return (
    <CreativeFooterStyling>
      <hr />
      <div className={element('split-info-wrapper')}>
        {!allValuesLocked && (
          <div data-testid="creative-review-badge-wrapper" className={element('content')}>
            <CreativeReviewBadge loading={false} alertProps={reviewAlertProps} creativeReview={creativeReview} />
          </div>
        )}
        <div className={element('content', 'interaction')}>
          <IngestPill ingestStatus={ingestionJobStatus} />
          <Tooltip id="vast-footer-tooltip" className={element('tooltip')} message={tooltipText} {...tooltipConfig}>
            {hasTranscodeAbort ? (
              <SetAssetErrorButton assets={assets} readonly={readonly} />
            ) : (
              <AssetIngestButton displayRetry={hasIngestError} disabled={readonly} />
            )}
          </Tooltip>
        </div>
      </div>
    </CreativeFooterStyling>
  );
};
