import Loader from '../../../Loader';
import type { CreativeBuilderProps } from '../CreativeBuilder';
import InstreamDxcCreativeBuilder from '../InstreamDxcCreativeBuilder';

export const PLACEHOLDER_DXC_TEXT = 'DXC creatives are not supported yet!';

export const wrapWithDxcCreativeBuilder = (
  component: (props: CreativeBuilderProps) => React.JSX.Element
): ((props: CreativeBuilderProps) => React.JSX.Element) => {
  return (props: CreativeBuilderProps): React.JSX.Element => {
    if (props.loadingCreativeData) return <Loader />;

    if (props?.creativeData?.getCreativeByIDV5.type === 'INSTREAM_DXC') {
      return <InstreamDxcCreativeBuilder {...props} />;
    }

    return component(props);
  };
};
