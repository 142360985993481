import React, { memo } from 'react';
import type { Cell } from 'react-table';

import {
  REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_MC_CAMPAIGN_UUID_TAG,
  REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_PROMPTS_ANSWER_TAG,
  REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_PROMPTS_ANSWER_XML_TEMPLATE,
  REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_TEMPLATE,
} from '../../..';
import { ClipboardReport, ShareIcon } from '../../../../../common/assets';
import Tooltip from '../../../../../common/Tooltip';
import bem from '../../../../../utils/bem';
import { replaceAll } from '../../../../../utils/string';
import type { CampaignModel } from '../../modelConverters';

const [block] = bem('mstr-report-link');

type Props = Cell<CampaignModel>;

const ShareIconImg = `<img src='${ShareIcon}' alt='share link' />`;

function MicroStrategyReportCell({ row }: Props): JSX.Element {
  const { id } = row.original;

  const promptsAnswerXML = replaceAll(
    REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_PROMPTS_ANSWER_XML_TEMPLATE,
    REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_MC_CAMPAIGN_UUID_TAG,
    id
  );
  const mstrLink = replaceAll(
    REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_TEMPLATE,
    REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_PROMPTS_ANSWER_TAG,
    encodeURIComponent(promptsAnswerXML)
  );

  return (
    <Tooltip id={id} message={`MicroStrategy Report ${ShareIconImg}`}>
      <a href={`${mstrLink}`} className={block()} target="_blank" rel="noopener noreferrer">
        <img src={ClipboardReport} alt="clipboard report" />
      </a>
    </Tooltip>
  );
}

const comparison = (prev: Props, next: Props): boolean => prev.row.original.id === next.row.original.id;

export default memo(MicroStrategyReportCell, comparison);
