import envConfig from '@hulu/env-config';

export enum ResultKeys {
  campaigns = 'campaigns',
  lineItems = 'lineItems',
  ads = 'ads',
  searchAssetsV5 = 'searchAssetsV5',
  lineItemsV5 = 'lineItemsV5',
}

export enum QueryVariablesKeys {
  ids = 'ids',
  campaignIds = 'campaignIds',
  lineItemIds = 'lineItemIds',
  traffickerEmailList = 'traffickerEmailList',
  accountExecutiveList = 'accountExecutiveList',
  adProductList = 'adProductList',
  schedule = 'schedule',
  sort = 'sort',
  order = 'order',
  limit = 'limit',
  offset = 'offset',
  searchTerm = 'searchTerm',
  brandIdList = 'brandIdList',
  industryIdList = 'industryIdList',
  agencyIdList = 'agencyIdList',
  isAddedValue = 'isAddedValue',
  isCoppaOrCaru = 'isCoppaOrCaru',
  isMakegood = 'isMakegood',
  isRotation = 'isRotation',
  isSequence = 'isSequence',
}

export const BASIC_FILTERS_QUERY_VARIABLES = [
  'generalPacingStatusList',
  'reviewStatusList',
  'pacingRiskList',
  'adStatusList',
  'orderTypeList',
  'tierNameList',
  'priorityValueList',
  'billableThirdPartyList',
  'publisherList',
  'adRatingList',
  'adTypeList',
  'creativeTypeList',
  'creativeReviewList',
  'countryList',
  'currencyList',
];

export const DELIVERY_FORENSICS_LINK = envConfig.REACT_APP_DELIVERY_FORENSICS_LINK;

export const REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_PROMPTS_ANSWER_TAG =
  envConfig.REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_PROMPTS_ANSWER_TAG;
export const REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_TEMPLATE =
  envConfig.REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_TEMPLATE;
export const REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_MC_CAMPAIGN_UUID_TAG =
  envConfig.REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_MC_CAMPAIGN_UUID_TAG;
export const REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_PROMPTS_ANSWER_XML_TEMPLATE =
  envConfig.REACT_APP_MICROSTRATEGY_CAMPAIGN_REPORT_URL_PROMPTS_ANSWER_XML_TEMPLATE;

export const DEFAULT_COLUMN_MIN_WIDTH = 28;

export const DEFAULT_LIMIT = 20;

export const MAX_LIMIT = 1000;

export const LOCAL_STORAGE_LOCATION_STATE = 'locationState';

export const CHANGE_PACING_TYPE_TO_AFAP_CONFIRM_MSG =
  'Are you sure you want the line item to deliver as fast as possible instead of evenly?';
export const CHANGE_PACING_TYPE_TO_EVEN_CONFIRM_MSG =
  'Are you sure you want the line item to deliver evenly instead of as fast as possible?';
