import { gql } from '@apollo/client';

// Data for Targeting Dropdowns
export const GET_ASSET_BY_ID = gql`
  query getAssetByID(
    $assetID: String!
    $paginationOptions: PaginationOptions
    $filterOptions: ReviewTaskV5FilterOptions
    $hasCreative: Boolean!
    $fetchMediaMetadata: Boolean!
  ) {
    getAssetByIDV5(assetId: $assetID) {
      name
      id
      assetTagList {
        description
        id
        value
        displayName
      }
      rejectionReasons
      review
      transcodeStatus
      filename
      countryCodes
    }
    getMediaMetadataByAssetIdV5(assetId: $assetID) @include(if: $fetchMediaMetadata) {
      format
    }
    getReviewTasksV5(paginationOptions: $paginationOptions, filterOptions: $filterOptions) @include(if: $hasCreative) {
      edges {
        node {
          id
          spReview
        }
      }
    }
  }
`;
