
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BaseAssetV5": [
      "DisplayAssetV5",
      "TextAssetV5",
      "VideoAssetV5"
    ],
    "BaseChange": [
      "MultiValueChange",
      "SingleValueChange"
    ],
    "BaseChangeValue": [
      "SimpleChangeValue"
    ],
    "BaseCreativeV5": [
      "AdSelectorSlateCreativeV5",
      "AdSelectorVideoCreativeV5",
      "AuditBaseCreativeV5",
      "BrightlineCreativeV5",
      "DraxCreativeV5",
      "GatewayGoCreativeV5",
      "InnovidCreativeV5",
      "InstreamDxcCreativeV5",
      "MagniteCreativeV5",
      "PauseCreativeV5",
      "SlateCreativeV5",
      "VASTVideoCreativeV5",
      "VideoCreativeV5"
    ],
    "BaseDateRangeV5": [
      "DateRangeV5"
    ],
    "ChangeLogUnionObject": [
      "CampaignV5",
      "AuditAdV5",
      "AuditLineItemV5",
      "AuditBaseCreativeV5"
    ],
    "Connection": [
      "AdsPageV5",
      "AgencyPage",
      "AssetsPageV5",
      "BaseCreativePageV5",
      "BrandsPage",
      "CampaignsPageV5",
      "ChangeLog",
      "ContentPartnerPage",
      "ExternalAssetsPageV5",
      "IndustryPage",
      "IngestionTracesPage",
      "LineItemsPageV5",
      "ReviewTaskPageV5",
      "TargetingPresetPage",
      "TargetingValuePage",
      "TraffickerEmailsPage",
      "UserPageV4",
      "UserRoleChangeLogPage"
    ],
    "CreativeTypePropertiesV5": [
      "BrightlineTypePropertiesV5",
      "DisplayTypePropertiesV5",
      "InnovidTypePropertiesV5",
      "PauseTypePropertiesV5",
      "SlateTypePropertiesV5",
      "VASTVideoTypePropertiesV5",
      "VideoTypePropertiesV5"
    ],
    "DxcCreativeV5": [
      "InstreamDxcCreativeV5"
    ],
    "DxcExecutable": [
      "EmailUmpDxcExecutable",
      "KervDxcExecutable",
      "PushUmpDxcExecutable",
      "QrDxcExecutable"
    ],
    "Edge": [
      "AdEdgeV5",
      "AgencyEdge",
      "AssetsEdgeV5",
      "BaseCreativeEdgeV5",
      "BrandEdge",
      "CampaignEdgeV5",
      "ChangeLogEntryEdge",
      "ContentPartnerEdge",
      "DspsAssetEdgeV5",
      "ExternalAssetsEdgeV5",
      "IndustryEdge",
      "IngestionTraceEdge",
      "LineItemEdgeV5",
      "ReviewTaskEdgeV5",
      "TargetingPresetEdge",
      "TargetingValueEdge",
      "TraffickerEmailEdge",
      "UserEdgeV4",
      "UserRoleChangeLogEdge"
    ],
    "ExternalAssetV5": [
      "DxcExternalAssetV5",
      "ImportExternalAssetV5",
      "PartnerExternalAssetV5",
      "ProgrammaticExternalAssetV5",
      "SelfServeExternalAssetV5"
    ],
    "LineItemV5Body": [
      "AuditLineItemV5",
      "LineItemV5"
    ],
    "Node": [
      "AdSelectorSlateCreativeV5",
      "AdSelectorVideoCreativeV5",
      "AdV5",
      "Agency",
      "AssetTagV5",
      "AuditBaseCreativeV5",
      "BaseTargetingValue",
      "Brand",
      "BrightlineCreativeV5",
      "CampaignV5",
      "ChangeLogNode",
      "ContentPartner",
      "CreativeReviewTaskV5",
      "CustomerBrand",
      "DisplayAssetV5",
      "DraxCreativeV5",
      "DspsAssetV5",
      "DxcExternalAssetV5",
      "ExternalAssetReviewTaskV5",
      "GatewayGoCreativeV5",
      "ImportExternalAssetV5",
      "Industry",
      "IngestionTrace",
      "InnovidCreativeV5",
      "InstreamDxcCreativeV5",
      "LineItemV5",
      "MagniteCreativeV5",
      "PartnerExternalAssetV5",
      "PauseCreativeV5",
      "ProgrammaticExternalAssetV5",
      "SelfServeExternalAssetV5",
      "SlateCreativeV5",
      "TargetingPreset",
      "TargetingValue",
      "TextAssetV5",
      "TraffickerEmail",
      "UserRoleChangeLogGroup",
      "UserV4",
      "VASTVideoCreativeV5",
      "VideoAssetV5",
      "VideoCreativeV5"
    ],
    "ReviewTaskV5": [
      "CreativeReviewTaskV5",
      "ExternalAssetReviewTaskV5"
    ],
    "TargetingTermValueBody": [
      "TargetingTermValue",
      "TargetingTermWithBaseValue"
    ],
    "TargetingValueBody": [
      "BaseTargetingValue",
      "TargetingValue"
    ],
    "UmpDxcExecutable": [
      "EmailUmpDxcExecutable",
      "PushUmpDxcExecutable"
    ],
    "VerifiedEntry": [
      "AdV5",
      "AdTag"
    ]
  }
};
      export default result;
    