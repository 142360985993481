import type { AdSelectorOption, CreativeV5Type, DaapiFeatureInput } from '../../../apis/graphql';
import type { Nullable } from '../../../models';
import type { CreativesBuilderValues, CreativeV5Types, DaapiEntity } from '../CreativeBuilders/types';

export const ARRAY_ASSET_KEYS = ['videoAssetList', 'templateAssetList', 'optionList'];

export function creativeTypeToAssetKey(creativeType: CreativeV5Type): Array<keyof CreativeV5Types> | null {
  switch (creativeType) {
    case 'BRIGHTLINE':
    case 'INNOVID':
    case 'VAST_VIDEO':
    case 'DRAX':
    case 'MAGNITE':
      return ['videoAssetList'];
    case 'PAUSE':
      return ['image'];
    case 'SLATE':
      return ['slate'];
    case 'AD_SELECTOR_VIDEO':
    case 'VIDEO':
      return ['video'];
    case 'GATEWAY_GO':
      return ['offerImage', 'video'];
    case 'AD_SELECTOR_SLATE':
      return ['logo', 'optionList'];
    case 'INSTREAM_DXC':
      return ['video', 'templateAssetList'];
    default:
      return null;
  }
}

export const getIdsFromAssetArrayProperty = (
  assetsList: Nullable<DaapiEntity | AdSelectorOption | DaapiFeatureInput>[]
): string[] => {
  return assetsList.reduce((acc: string[], asset: Nullable<DaapiEntity | AdSelectorOption | DaapiFeatureInput>) => {
    if (!!asset) {
      if ('id' in asset && !!asset?.id) {
        acc.push(asset.id);
      } else if ('thumbnail' in asset && asset?.thumbnail?.id) {
        acc.push(asset.thumbnail.id);
      }
    }

    return acc;
  }, []);
};

const getAssetIdsByKey = (creative: CreativesBuilderValues, assetKey: keyof CreativeV5Types): string[] => {
  if (!creative) return [];

  const creativeProperty = creative[assetKey];

  if (creativeProperty && Array.isArray(creativeProperty) && ARRAY_ASSET_KEYS.includes(assetKey)) {
    return getIdsFromAssetArrayProperty(creativeProperty);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const assetObject: any = !!creativeProperty && creativeProperty?.hasOwnProperty('id') ? creativeProperty : null;
  const assetId = !!assetObject && typeof assetObject !== 'string' ? assetObject.id : null;

  return !!assetId ? [assetId] : [];
};

export function getAssetIds(creative: CreativesBuilderValues | null): string[] {
  if (!creative) {
    return [];
  }
  const assetKeys = creativeTypeToAssetKey(creative.type);

  if (!assetKeys) return [];

  return assetKeys.map((key) => getAssetIdsByKey(creative, key)).flat();
}
