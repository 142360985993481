import React, { useState } from 'react';

import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import type { Nullable } from '../../../../../models';
import Button from '../../../../Button';
import { ingestButton } from '../../configs';
import { setAssetPublishingError } from '../../hooks/setAssetPublishingError';

/**
 * Displays a button that will trigger an asset ingestion mutation when clicked.
 * Will be disabled if the Ad form is dirty.
 * @param displayRetry Displays a retry message if true.
 */
export const SetAssetErrorButton: React.FC<{
  readonly?: boolean;
  assets?: Nullable<AssetExtended>[] | null;
}> = ({ assets, readonly }) => {
  const [ingestBusy, setIngestBusy] = useState(false);

  const handleClick = async (): Promise<void> => {
    if (!assets?.length) return;
    setIngestBusy(true);

    const results = await Promise.all(
      assets.map((asset) => {
        return asset?.transcodeStatus === 'PUBLISHING_ABORT' ? setAssetPublishingError(asset) : true;
      })
    );

    // If any attempt failed, re-enable the button to try again
    if (results.includes(false)) setIngestBusy(false);
  };

  return (
    <Button data-testid="set-asset-error-button" size="medium" onClick={handleClick} disabled={ingestBusy || readonly}>
      {ingestButton.defaultText}
    </Button>
  );
};
