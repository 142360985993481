import { bem } from 'mission-control-common-components';
import React from 'react';

import type { AssetExtended } from '../../../../../common/_ForCommonLibrary/types/extended';
import type { CreativeReviewStatus } from '../../../../../configs';
import Tooltip from '../../../../Tooltip';
import CreativeReviewBadge from '../../../CreativeReviewBadge';
import { ingestButton } from '../../configs';
import { reviewAlertPropsMap } from '../../utils';
import { CreativeFooterStyling } from '../CreativeFooterStyling';
import { SetAssetErrorButton } from '../SetAssetErrorButton';

const element = bem('creative-footer')[1];

export const CreativeFooter: React.FC<{
  assets?: AssetExtended[] | null;
  creativeReviewStatus?: CreativeReviewStatus;
  hasTranscodeAbort?: boolean;
  isLoading?: boolean;
  readonly?: boolean;
}> = ({ assets, hasTranscodeAbort, isLoading, readonly, creativeReviewStatus }) => {
  const reviewAlertProps = creativeReviewStatus ? reviewAlertPropsMap?.[creativeReviewStatus] : null;
  const spReviewLocked = assets?.length && assets?.[0].reviewTask?.spReview === 'LOCKED';

  if (!reviewAlertProps || spReviewLocked) return null;
  const { tooltipConfig, tooltipText } = ingestButton;

  return (
    <CreativeFooterStyling>
      <hr />
      <div className={element('split-info-wrapper')}>
        <div className={element('content', 'information')}>
          <CreativeReviewBadge
            loading={isLoading}
            creativeReview={creativeReviewStatus}
            alertProps={reviewAlertProps}
          />
        </div>
        {hasTranscodeAbort && (
          <div className={element('content', 'interaction')}>
            <Tooltip id="vast-footer-tooltip" className={element('tooltip')} message={tooltipText} {...tooltipConfig}>
              <SetAssetErrorButton readonly={readonly} assets={assets} />
            </Tooltip>
          </div>
        )}
      </div>
    </CreativeFooterStyling>
  );
};
