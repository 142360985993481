import type { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import { theme } from '../../../../styles/styled';
import { gridPx } from '../../../../styles/styled/constants';

const publishedStyle = css`
  border-color: ${theme.color.custom.brandPrimary};
  outline: 1px solid ${theme.color.custom.brandPrimary};
`;

// removed   height: 15.75em;
export const Card = styled.div<{ published?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0.25em;
  padding: 0.125em;
  background-color: ${theme.color.design.white};
  border: 1px solid ${theme.color.design.gray4};

  ${(props): FlattenSimpleInterpolation | string => (props.published ? publishedStyle : '')}

  .pill {
    flex-shrink: 1;
    font-size: 8px;
    padding: ${gridPx(0.5)} ${gridPx()};
    width: fit-content;
    margin: ${gridPx(0.5)};
  }
`;
