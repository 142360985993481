import { gql } from '@apollo/client';

import { CREATIVE_METADATA } from '../../../../common/AdForm/hooks/fragments/creativeMetadataFragment';

/**
 * Create and update creatives on the Ads Page
 * Do not request an updated CREATIVE_BUILDER_FRAGMENT in the responses
 * Rather, provide the Creative ID to the CREATE_ADS_PAGE_AD or UPDATE_ADS_PAGE_AD mutation and request the parent Ad entity in those mutations
 */

export const CREATE_AD_SELECTOR_SLATE_CREATIVE = gql`
  mutation CreateAdSelectorSlateCreative($creativeInput: AdSelectorSlateCreativeInputV5!) {
    createAdSelectorSlateCreativeV5(input: $creativeInput) {
      id
    }
  }
`;

export const CREATE_AD_SELECTOR_VIDEO_CREATIVE = gql`
  mutation CreateAdSelectorVideoCreative($creativeInput: AdSelectorVideoCreativeInputV5!) {
    createAdSelectorVideoCreativeV5(input: $creativeInput) {
      id
    }
  }
`;

export const CREATE_VIDEO_CREATIVE = gql`
  mutation CreateVideoCreative($creativeInput: VideoCreativeInputV5!) {
    createVideoCreativeV5(input: $creativeInput) {
      id
    }
  }
`;

export const CREATE_SLATE_CREATIVE = gql`
  mutation CreateSlateCreative($creativeInput: SlateCreativeInputV5!) {
    createSlateCreativeV5(input: $creativeInput) {
      id
    }
  }
`;

export const CREATE_PAUSE_CREATIVE = gql`
  mutation CreatePauseCreative($creativeInput: PauseCreativeInputV5!) {
    createPauseCreativeV5(input: $creativeInput) {
      id
    }
  }
`;

export const CREATE_BRIGHTLINE_CREATIVE = gql`
  mutation CreateBrightlineCreative($creativeInput: BrightlineCreativeInputV5!) {
    createBrightlineCreativeV5(input: $creativeInput) {
      id
    }
  }
`;

export const CREATE_DRAX_CREATIVE = gql`
  mutation CreateDraxCreativeV5($creativeInput: DraxCreativeInputV5!) {
    createDraxCreativeV5(input: $creativeInput) {
      id
    }
  }
`;

export const CREATE_INNOVID_CREATIVE = gql`
  mutation CreateInnovidCreative($creativeInput: InnovidCreativeInputV5!) {
    createInnovidCreativeV5(input: $creativeInput) {
      id
    }
  }
`;

export const CREATE_GATEWAY_GO_CREATIVE = gql`
  mutation CreateGatewayGoCreative($creativeInput: GatewayGoCreativeInputV5!) {
    createGatewayGoCreativeV5(input: $creativeInput) {
      id
    }
  }
`;

export const CREATE_VAST_VIDEO_CREATIVE = gql`
  mutation CreateVastVideoCreative($creativeInput: VastVideoCreativeInputV5!) {
    createVastVideoCreativeV5(input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_AD_SELECTOR_SLATE_CREATIVE = gql`
  mutation UpdateAdSelectorSlateCreative($id: ID!, $creativeInput: AdSelectorSlateCreativeInputV5!) {
    updateAdSelectorSlateCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_AD_SELECTOR_VIDEO_CREATIVE = gql`
  mutation UpdateAdSelectorVideoCreative($id: ID!, $creativeInput: AdSelectorVideoCreativeInputV5!) {
    updateAdSelectorVideoCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_VIDEO_CREATIVE = gql`
  mutation UpdateVideoCreative($id: ID!, $creativeInput: VideoCreativeInputV5!) {
    updateVideoCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_SLATE_CREATIVE = gql`
  mutation UpdateSlateCreative($id: ID!, $creativeInput: SlateCreativeInputV5!) {
    updateSlateCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_PAUSE_CREATIVE = gql`
  mutation UpdatePauseCreative($id: ID!, $creativeInput: PauseCreativeInputV5!) {
    updatePauseCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_BRIGHTLINE_CREATIVE = gql`
  mutation UpdateBrightlineCreative($id: ID!, $creativeInput: BrightlineCreativeInputV5!) {
    updateBrightlineCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_INNOVID_CREATIVE = gql`
  mutation UpdateInnovidCreative($id: ID!, $creativeInput: InnovidCreativeInputV5!) {
    updateInnovidCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_GATEWAY_GO_CREATIVE = gql`
  mutation UpdateGatewayGoCreative($id: ID!, $creativeInput: GatewayGoCreativeInputV5!) {
    updateGatewayGoCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_VAST_VIDEO_CREATIVE = gql`
  mutation UpdateVastVideoCreative($id: ID!, $creativeInput: VastVideoCreativeInputV5!) {
    updateVastVideoCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_MAGNITE_CREATIVE = gql`
  mutation UpdateMagniteCreative($id: ID!, $creativeInput: MagniteCreativeInputV5!) {
    updateMagniteCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_DRAX_CREATIVE = gql`
  mutation UpdateDraxCreativeV5($id: ID!, $creativeInput: DraxCreativeInputV5!) {
    updateDraxCreativeV5(id: $id, input: $creativeInput) {
      id
    }
  }
`;

export const UPDATE_CREATIVE_METADATA_BY_CREATIVE_ID = gql`
  mutation UpdateCreativeMetadataByCreativeIdV5(
    $creativeId: ID!
    $countryCodes: [String!]!
    $isLegalApproved: Boolean!
  ) {
    updateCreativeMetadataByCreativeIdV5(
      creativeId: $creativeId
      countryCodes: $countryCodes
      isLegalApproved: $isLegalApproved
    ) {
      ...creativeMetadata
    }
  }
  ${CREATIVE_METADATA}
`;
