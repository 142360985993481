import type { ValueOf } from '../../../../types/util';
import type { IngestionStatusMap, ReviewStatus, SPReviewStatusMap, TranscodeStatusMap } from '../constants';
import type { LabelType } from '../util/LabelType';

export enum StageLabels {
  Ingest = 'INGEST',
  Transcode = 'TRANSCODE',
  Review = 'REVIEW',
  SpReview = 'SP-REVIEW',
}

type IngestLabel = {
  stage: StageLabels.Ingest;
  label: ValueOf<typeof IngestionStatusMap>;
  type: ValueOf<typeof LabelType>;
};

type TranscodeLabel = {
  stage: StageLabels.Transcode;
  label: ValueOf<typeof TranscodeStatusMap>;
  type: ValueOf<typeof LabelType>;
};

type ReviewLabel = {
  stage: StageLabels.Review;
  label: ValueOf<typeof ReviewStatus>;
  type: ValueOf<typeof LabelType>;
};

type SPReviewLabel = {
  stage: StageLabels.SpReview;
  label: ValueOf<typeof SPReviewStatusMap>;
  type: ValueOf<typeof LabelType>;
};

export type StatusLabel = IngestLabel | TranscodeLabel | ReviewLabel | SPReviewLabel | null;
