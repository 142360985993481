import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';

import type {
  CreativeV5Type,
  SearchCreativeSortOptions,
  SearchCreativesQuery as GQLQueryResult,
  SearchCreativesQueryVariables,
} from '../../../../apis/graphql';
import { OrderOption, SearchCreativeSortOption } from '../../../../apis/graphql';
import { SEARCH_CREATIVES } from './queries';

export interface SearchCreativesResult {
  id: string; // Used to fetch creative on selection
  name: string; // Display in search results
}

export interface UseSearchCreativesResult {
  loading: boolean;
  error?: ApolloError;
  results: SearchCreativesResult[]; // return empty array if no results are returned by API
  onNext: () => Promise<void>;
  hasMore: boolean;
}

const sortOptions: SearchCreativeSortOptions = {
  sort: SearchCreativeSortOption.CreatedAt,
  order: OrderOption.Desc,
};

export function paramsToQueryVariables(
  query: string,
  limit: number,
  creativeTypes?: CreativeV5Type[]
): SearchCreativesQueryVariables {
  return {
    filterOptions: {
      searchTerm: query,
      type: creativeTypes,
    },
    paginationOptions: { limit, offset: 0 },
    sortOptions,
  };
}

export function searchCreativeResultToModel(gqlResult?: GQLQueryResult): SearchCreativesResult[] {
  return gqlResult?.searchCreatives?.edges.map(({ node: { id, name } }) => ({ id, name })) || [];
}

export function useSearchCreatives(query: string, creativeTypes?: CreativeV5Type[]): UseSearchCreativesResult {
  const limit = 50;
  const trimmedQuery = query.trim();

  const { error, loading, data, fetchMore } = useQuery<GQLQueryResult, SearchCreativesQueryVariables>(
    SEARCH_CREATIVES,
    {
      skip: !trimmedQuery, // skip query if no valid name is provided
      variables: paramsToQueryVariables(trimmedQuery, limit, creativeTypes),
    }
  );

  const onNext = async (): Promise<void> => {
    const offset = data?.searchCreatives.edges.length ?? 0;
    try {
      await fetchMore({
        variables: {
          paginationOptions: { limit, offset },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    loading,
    error,
    results: searchCreativeResultToModel(data),
    onNext,
    hasMore: data?.searchCreatives.pageInfo.hasNextPage ?? false,
  };
}
