import type { BaseCreativeV5 } from '../../../../../apis/graphql/types';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik/types';
import type { CreativesBuilderValues } from '../../../../../common/AdForm/CreativeBuilders/types';
import { createOrUpdateCreative } from './createOrUpdateCreative';
import { transformValuesToProperShapeForGraph } from './transformValuesToProperShapeForGraph';

// Create (if new) or update (if existing) creative if present
// If no creative present, return null so that the Ad can be saved with no associated creative ID
export async function saveCreative(values: AdFormValues): Promise<BaseCreativeV5['id'] | null> {
  // Creative builder is empty => return null ID
  if (values.creative.mode === 'selectOption') return null;
  if (values.creative.type === 'INSTREAM_DXC') return null;

  const creative = transformValuesToProperShapeForGraph(values) as CreativesBuilderValues;
  const valuesForGraph = { ...values, creative };

  // Creative exists (has been created) => update creative and return ID
  // Creative does not exist (has not been created) => create creative and return ID
  return await createOrUpdateCreative(valuesForGraph, !!values.creative.id ? 'update' : 'create');
}
