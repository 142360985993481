import './TextInputWithActions.scss';

import { IconCheck, IconClose, IconEdit } from '@hulu-react-style-components/icons';
import { TextField } from '@hulu/react-style-components';
import { bem } from 'mission-control-common-components';
import type { MouseEventHandler } from 'react';
import { useEffect, useState } from 'react';
import React from 'react';

const [block, element] = bem('text-input-with-actions');

export type TextInputWithActionsProps = {
  initialValue: string;
  initialEditingState?: boolean;
  initialError?: string | null;
  onSubmit: (value: string) => string | null;
};

const TextInputWithActions = ({
  initialValue,
  onSubmit,
  initialError,
  initialEditingState,
}: TextInputWithActionsProps): React.JSX.Element => {
  const [isEditing, setIsEditing] = useState(initialEditingState);
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(initialError);

  // reset text field value, when initialValue is changed
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleCancel = (): void => {
    setIsEditing(false);
    setError(null);
    setValue(initialValue);
  };

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = () => {
    const validationError = onSubmit(value);
    setError(validationError);

    if (!validationError) setIsEditing(false);
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const blockClassModifier = error && isEditing ? 'error' : '';

  return (
    <div className={block(blockClassModifier)}>
      {isEditing ? (
        <div className={element('input-block')}>
          <TextField data-testid="text-field-input" value={value} onChange={handleTextFieldChange} />
          {error && (
            <div data-testid="error-block" className={element('error')}>
              {error}
            </div>
          )}
        </div>
      ) : (
        <>
          <div data-testid="value-label" title={initialValue} className={element('value-label')}>
            {initialValue}
          </div>
          <div className={element('edit-icon')}>
            <IconEdit data-testid="edit-icon" onClick={(): void => setIsEditing(true)} />
          </div>
        </>
      )}
      {isEditing && (
        <div data-testid="buttons-block" className={element('buttons')}>
          <button
            data-testid="close-button"
            className={element('cancel-btn')}
            onClick={handleCancel}
            aria-label="cancel button"
          >
            <IconClose title="Cancel Changes" />
          </button>
          <button
            data-testid="submit-button"
            className={element('submit-btn')}
            onClick={handleSubmit}
            aria-label="submit button"
          >
            <IconCheck title="Submit Changes" />
          </button>
        </div>
      )}
    </div>
  );
};

export default TextInputWithActions;
