import './AssetSubTab.scss';

import { Tab, TabNavigation } from '@hulu/react-style-components';
import { bem } from 'mission-control-common-components';
import { useState } from 'react';

import type { AssetV5 } from '../../../../../models';

type Props<T> = {
  previewElement: (props: T) => React.JSX.Element;
  itemsProps: T[];
  emptyMessage: string;
};

const [block, element] = bem('asset-sub-tab');

const MAX_NAVIGATION_CHILDREN = 100;
const MAX_ITEMS_FIT_IN_SUB_TAB = 4;

const AssetSubTab = <T extends { asset: Partial<AssetV5> }>({
  previewElement: PreviewComponent,
  itemsProps,
  emptyMessage,
}: Props<T>): React.JSX.Element => {
  const [selectedTab, setSelectedTab] = useState('1');

  const handleTabClick = (type: string): void => {
    setSelectedTab(type);
  };

  if (!itemsProps.length) {
    return <div className={element('empty-message')}>{emptyMessage}</div>;
  }

  const tabsClassName = itemsProps.length > MAX_ITEMS_FIT_IN_SUB_TAB ? 'auto' : 'fit';

  return (
    <div className={block()}>
      {itemsProps.length === 1 ? (
        <div className={element('tab-child-wrapper')}>
          <PreviewComponent {...itemsProps[0]} />
        </div>
      ) : (
        <TabNavigation
          maxChildren={MAX_NAVIGATION_CHILDREN}
          className={element('segmented-control', tabsClassName)}
          selectedTab={selectedTab}
          onTabClick={handleTabClick}
        >
          {itemsProps.map((props, index) => {
            return (
              <Tab key={props.asset.id} title={`${index + 1}`} tabIndex={index}>
                <div className={element('tab-child-wrapper')}>
                  <PreviewComponent {...props} />
                </div>
              </Tab>
            );
          })}
        </TabNavigation>
      )}
    </div>
  );
};

export default AssetSubTab;
