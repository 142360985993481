import type { AdSelectorOption } from '../../../apis/graphql';
import { CreativeV5Type } from '../../../apis/graphql';
import type { Nullable } from '../../../models';
import { TAG_VALIDATION_MESSAGE, URL_REGEX, URL_VALIDATION_MESSAGE } from '../../constants';
import type { AdFormValues } from '../adFormik';
import { AdFormCreativeFields } from '../adFormik/fields';
import type { AssetValidationParams, CollectEmptyFieldsParams, ValidationError } from '../types';
import * as validationErrors from './const';
import { DUPLICATE_THUMBNAIL_ERROR_MESSAGE } from './const';

const validatePauseCreative = (creative: AdFormValues['creative']): Nullable<ValidationError> => {
  const fieldsWithErrors = [];

  if (!creative.name) {
    fieldsWithErrors.push(AdFormCreativeFields.Name);
  }

  if (!creative.image?.id) {
    fieldsWithErrors.push(AdFormCreativeFields.Image);
  }

  if (!creative.accent) {
    fieldsWithErrors.push(AdFormCreativeFields.Accent);
  }

  if (!creative.altText) {
    fieldsWithErrors.push(AdFormCreativeFields.AltText);
  }

  if (fieldsWithErrors.length) {
    return {
      fields: fieldsWithErrors,
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: validationErrors.FILL_CREATIVE_FIELDS,
    };
  }
  return null;
};

const validateVastLikeCreative = (creative: AdFormValues['creative']): Nullable<ValidationError> => {
  const fieldsWithErrors = [];

  if (!creative.name) {
    fieldsWithErrors.push(AdFormCreativeFields.Name);
  }

  if (!creative.template) {
    fieldsWithErrors.push(AdFormCreativeFields.Template);
  }

  if (!creative.url) {
    fieldsWithErrors.push(AdFormCreativeFields.Url);
  }

  if (fieldsWithErrors.length) {
    return {
      fields: fieldsWithErrors,
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: validationErrors.FILL_CREATIVE_FIELDS,
    };
  } else if (!creative.isSubmitted) {
    return {
      fields: [AdFormCreativeFields.Url],
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: validationErrors.SUBMIT_TAG,
    };
  }

  return null;
};

const validateVastAndVideoCreatives = (creative: AdFormValues['creative']): Nullable<ValidationError> => {
  const hasInvalidURL = URL_REGEX.test(creative.url!);

  if (!creative.name) {
    return {
      fields: [AdFormCreativeFields.Name],
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: validationErrors.FILL_CREATIVE_FIELDS,
    };
  }

  if (creative.url && !hasInvalidURL) {
    return {
      fields: [AdFormCreativeFields.Url],
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: TAG_VALIDATION_MESSAGE,
    };
  }

  return null;
};

export const validateGatewayGoCreative = ({
  name,
  destinationUrl,
  livingRoomCopy,
  emailBody,
  emailSubject,
  pushBody,
  pushTitle,
  video,
  offerImage,
}: AdFormValues['creative']): Nullable<ValidationError> => {
  const hasValidURL = URL_REGEX.test(destinationUrl!);
  const emptyFields: AdFormCreativeFields[] = [];

  if (!name) {
    emptyFields.push(AdFormCreativeFields.Name);
  }

  if (!livingRoomCopy) {
    emptyFields.push(AdFormCreativeFields.LivingRoomCopy);
  }

  if (!destinationUrl) {
    emptyFields.push(AdFormCreativeFields.DestinationUrl);
  }

  if (!pushTitle) {
    emptyFields.push(AdFormCreativeFields.PushTitle);
  }

  if (!pushBody) {
    emptyFields.push(AdFormCreativeFields.PushBody);
  }

  if (!emailSubject) {
    emptyFields.push(AdFormCreativeFields.EmailSubject);
  }

  if (!emailBody) {
    emptyFields.push(AdFormCreativeFields.EmailBody);
  }

  if (!offerImage || !offerImage.id) {
    emptyFields.push(AdFormCreativeFields.OfferImage);
  }

  if (!video || !video.id) {
    emptyFields.push(AdFormCreativeFields.Video);
  }

  if (destinationUrl && !hasValidURL) {
    return {
      fields: [AdFormCreativeFields.DestinationUrl],
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: URL_VALIDATION_MESSAGE,
    };
  }

  if (emptyFields.length) {
    return {
      fields: emptyFields,
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: validationErrors.FILL_CREATIVE_FIELDS,
    };
  }

  return null;
};

export const validateAdSelectorVideo = ({ name, video }: AdFormValues['creative']): Nullable<ValidationError> => {
  const emptyFields: AdFormCreativeFields[] = [];

  if (!name) {
    emptyFields.push(AdFormCreativeFields.Name);
  }

  if (!video || !video.id) {
    emptyFields.push(AdFormCreativeFields.Video);
  }

  if (emptyFields.length) {
    return {
      fields: emptyFields,
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: validationErrors.FILL_CREATIVE_FIELDS,
    };
  }

  return null;
};

export const validateAdSelectorOption = ({
  index,
  thumbnail,
  caption,
  adSelectorVideoCreativeId,
}: AdSelectorOption): string[] => {
  const emptyFields: string[] = [];
  const indexInOptionList = index - 1;

  if (!index) {
    emptyFields.push(`${AdFormCreativeFields.OptionList}[${indexInOptionList}].index`);
  }

  if (!thumbnail || !thumbnail.id) {
    emptyFields.push(`${AdFormCreativeFields.OptionList}[${indexInOptionList}].thumbnail`);
  }

  if (!caption) {
    emptyFields.push(`${AdFormCreativeFields.OptionList}[${indexInOptionList}].caption`);
  }

  if (!adSelectorVideoCreativeId) {
    emptyFields.push(`${AdFormCreativeFields.OptionList}[${indexInOptionList}].adSelectorVideoCreativeId`);
  }

  return emptyFields;
};

const checkForDuplicateAssetIds = ({ id, fieldPath, uniqueAssetIds, duplicateFields }: AssetValidationParams): void => {
  if (uniqueAssetIds.has(id)) {
    duplicateFields.push(fieldPath);
  } else {
    uniqueAssetIds.add(id);
  }
};

const collectEmptyFields = ({ name, headline, voiceoverUrl, logo }: CollectEmptyFieldsParams): string[] => {
  const fields: string[] = [];

  if (!name) fields.push(AdFormCreativeFields.Name);
  if (!headline) fields.push(AdFormCreativeFields.Headline);
  if (!voiceoverUrl) fields.push(AdFormCreativeFields.VoiceoverUrl);
  if (!logo?.id) fields.push(AdFormCreativeFields.Logo);

  return fields;
};

export const validateAdSelectorSlate = ({
  name,
  headline,
  voiceoverUrl,
  logo,
  optionList,
}: AdFormValues['creative']): Nullable<ValidationError> => {
  const hasValidVoiceURL = URL_REGEX.test(voiceoverUrl!);
  const duplicateFields: string[] = [];

  const uniqueAssetIds = new Set<string>();

  if (voiceoverUrl && !hasValidVoiceURL) {
    return {
      fields: [AdFormCreativeFields.VoiceoverUrl],
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: URL_VALIDATION_MESSAGE,
    };
  }

  const emptyFields = collectEmptyFields({ name, headline, voiceoverUrl, logo });

  if (logo?.id) {
    checkForDuplicateAssetIds({
      id: logo.id,
      fieldPath: AdFormCreativeFields.Logo,
      uniqueAssetIds,
      duplicateFields,
    });
  }

  const optionListErrors = optionList?.reduce((acc, option, index) => {
    const errors = validateAdSelectorOption(option);

    if (option.thumbnail?.id) {
      checkForDuplicateAssetIds({
        id: option.thumbnail?.id,
        fieldPath: `${AdFormCreativeFields.OptionList}[${index}].thumbnail`,
        uniqueAssetIds,
        duplicateFields,
      });
    }

    if (errors.length) {
      acc.push(errors);
    }
    return acc;
  }, new Array<string[]>());

  if (optionListErrors && optionListErrors.length > 1) {
    emptyFields.push(...optionListErrors?.flat());
  }

  if (emptyFields.length) {
    return {
      fields: emptyFields as AdFormCreativeFields[],
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: validationErrors.FILL_CREATIVE_FIELDS,
    };
  }

  if (duplicateFields.length) {
    return {
      fields: duplicateFields as AdFormCreativeFields[],
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: DUPLICATE_THUMBNAIL_ERROR_MESSAGE,
    };
  }

  return null;
};

export const validateCreativeByType = (creative: AdFormValues['creative']): Nullable<ValidationError> => {
  // applies different validation for each creative type
  switch (creative.type) {
    case CreativeV5Type.Pause:
      return validatePauseCreative(creative);
    case CreativeV5Type.Innovid:
    case CreativeV5Type.Brightline:
      return validateVastLikeCreative(creative);
    case CreativeV5Type.VastVideo:
    case CreativeV5Type.Video:
      return validateVastAndVideoCreatives(creative);
    case CreativeV5Type.GatewayGo:
      return validateGatewayGoCreative(creative);
    case CreativeV5Type.AdSelectorVideo:
      return validateAdSelectorVideo(creative);
    case CreativeV5Type.AdSelectorSlate:
      return validateAdSelectorSlate(creative);
    default:
      // default validation for all creatives that don't have specific validation
      if (!creative.name) {
        return {
          fields: [AdFormCreativeFields.Name],
          message: validationErrors.CREATIVE_ERROR_MESSAGE,
          description: validationErrors.FILL_CREATIVE_FIELDS,
        };
      }

      return null;
  }
};
