import './CreativeReviewBadge.scss';

import type { ReactNode } from 'react';
import React from 'react';

import Alert from '../../../common/Alert';
import type { AlertProps } from '../../../common/Alert/Alert';
import Loader from '../../../common/Loader';
import type { Nullable } from '../../../models';
import bem from '../../../utils/bem';

interface CreativeReviewBadgeProps {
  alertProps?: Nullable<AlertProps>;
  loading?: boolean;
  creativeReview?: string;
}

const [block] = bem('creative-review-badge');

function withWrapper(component: ReactNode): JSX.Element {
  return <div className={block()}>{component}</div>;
}

const CreativeReviewBadge: React.FC<CreativeReviewBadgeProps> = ({ alertProps, creativeReview, loading }) => {
  if (loading) return withWrapper(<Loader />);

  if (!creativeReview) return withWrapper(null);

  return withWrapper(alertProps ? <Alert {...alertProps} /> : null);
};

export default CreativeReviewBadge;
