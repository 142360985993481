import React from 'react';

import type { StatusPillStatus } from '../StatusPill';
import { StatusPill } from '../StatusPill';
import { StageLabels } from './types';
import { getBestLabel, getIngestLabel, getReviewLabel, getSPReviewLabel, getTranscodeLabel } from './util';

export type Props = {
  className?: string;
  ingestionStatus?: string | null;
  reviewStatus?: string | null;
  spReviewStatus?: string | null;
  transcodeStatus?: string | null;
  additionalInfo?: React.JSX.Element | null;
};

/**
 * Renders a pill component with the appropriate styling and messaging according
 * to the Ingest, Transcode, and Review status values for an asset.
 */
export const AssetStatusPill: React.FC<Props> = ({
  className,
  ingestionStatus,
  transcodeStatus,
  reviewStatus,
  spReviewStatus,
  additionalInfo,
}) => {
  const ingest = getIngestLabel(ingestionStatus);
  const transcode = getTranscodeLabel(transcodeStatus);
  const review = getReviewLabel(reviewStatus);
  const spReview = getSPReviewLabel(spReviewStatus);

  const display = getBestLabel(ingest, transcode, review, spReview);

  // When no labels are available, we won't show a pill
  if (!display) return null;

  return (
    <StatusPill data-testid="status-pill" status={display.type as StatusPillStatus} className={className}>
      <span className="label">
        {display.stage === StageLabels.SpReview ? display.label : `${display.stage}: ${display.label}`}
      </span>
      {additionalInfo}
    </StatusPill>
  );
};
