import './TodoItem.scss';

import { IconAlertErrorRed, IconAlertSuccessGreen } from '@hulu-react-style-components/icons';
import React, { useMemo } from 'react';

import bem from '../../utils/bem';
import IncompleteCheck from './IncompleteCheck.svg';

const [block, element] = bem('todo-item');

interface TodoItemProps {
  isComplete: boolean;
  isFailed?: boolean;
  text: string;
  showCheck?: boolean;
}

function TodoItem({ isComplete, text, isFailed, showCheck = true }: TodoItemProps): React.JSX.Element {
  const completionComponent = useMemo(() => {
    if (isFailed) return <IconAlertErrorRed className={element('check', 'failed')} title="Validation Failed Check" />;

    if (isComplete) return <IconAlertSuccessGreen className={element('check', 'complete')} title="Complete Check" />;

    return <img className={element('check', 'incomplete')} alt="Incomplete Check" src={IncompleteCheck} />;
  }, [isComplete, isFailed]);

  return (
    <div className={block()}>
      <span className={element('text')}>{text}</span>
      {showCheck && (
        <span role="checkbox" aria-checked={isComplete} className={element('check-wrapper')}>
          {completionComponent}
        </span>
      )}
    </div>
  );
}

export default TodoItem;
