import { ActiveDayTypeV5 } from '../../../apis/graphql';
import type { AdFormValues } from '../../../common/AdForm/adFormik';
import { getDefaultAdSelectorSlateOptionList } from '../../../common/AdForm/adFormik';
import { DEFAULT_SCHEDULE_OBJECT } from '../../../common/AdForm/hooks/modelConverters';
import type { AdsPageAd } from '../../../common/AdForm/hooks/types';
import { mockAdFormTargetingTermValues } from '../../../common/AdForm/mocks';
import { defaultSupportedTimezones } from '../../../common/commonMocks/timezones';
import { adTypes } from '../../../configs';

export const mockAd: AdsPageAd = {
  id: 'some-guid',
  name: 'Ad Name',
  type: adTypes.VIDEO,
  creative: null,
  createdAt: '2020-10-23T23:44:01Z',
  updatedAt: '2020-10-23T23:44:01Z',
  status: { key: 'ON', displayName: 'ON' },
  review: { key: 'APPROVED', displayName: 'Approved' },
  rating: { key: 'CHILD_SAFE', displayName: 'Child Safe' },
  dirty: false,
  frequencyCapList: [],
  viewabilityVendorList: [],
  adRotation: null,
  adTagList: [],
  targetingTermValues: [],
  targetingRule: { id: '', definition: null },
  actualStart: null,
  schedule: DEFAULT_SCHEDULE_OBJECT,
  startDate: null,
  endDate: null,
  lineItem: {
    id: 'line-item-id',
    schedule: {
      timezone: defaultSupportedTimezones.ET,
      dateRangeList: [],
      dayPartList: [],
      deliveryInViewerTime: false,
    },
    parentLineOmsLink: {
      id: 'parent-line-oms-link-kid',
    },
  },
  unDeletable: false,
  adSequence: null,
  sequence: null,
};

export const mockConvertedAdToFormValues: AdFormValues = {
  name: 'Duplicate ad mock',
  type: 'VIDEO',
  review: {
    displayName: 'Draft',
    key: 'DRAFT',
  },
  creativeChanged: false,
  schedule: {
    dateRangeList: [
      {
        pacingShare: null,
        startDate: '2021-11-11T00:00:00',
        endDate: '2021-12-12T23:59:59',
      },
    ],
    dayPartList: [
      {
        activeDayList: [ActiveDayTypeV5.Monday, ActiveDayTypeV5.Friday],
        firstActiveHour: 19,
        lastActiveHour: 21,
      },
    ],
    deliveryInViewerTime: false,
    timezone: 'America/New_York',
  },
  isLastDateRangeDisabled: false,
  deliveryStatus: false,
  iasCampaignLineItemId: '',
  rating: 'CHILD_SAFE',
  creative: {
    id: '',
    name: '',
    type: 'VIDEO',
    filesize: 0,
    importMethod: 'upload',
    creativeLibraryId: '',
    mode: 'selectOption',
    featureList: [],
    image: { id: '' },
    logo: { id: '' },
    offerImage: { id: '' },
    poster: { id: '' },
    slate: { id: '' },
    template: '',
    url: '',
    isSubmitted: false,
    video: { id: '' },
    videoAssetList: [],
    accent: null,
    altText: '',
    pushBody: '',
    pushTitle: '',
    emailBody: '',
    emailSubject: '',
    destinationUrl: '',
    livingRoomCopy: '',
    voiceoverUrl: '',
    headline: '',
    randomOrder: false,
    optionList: getDefaultAdSelectorSlateOptionList(),
  },
  frequencyCapList: [
    {
      limit: 10,
      duration: 20,
      enabled: false,
      type: 'AD',
      durationUnit: {
        displayName: 'Hours',
        key: 'HOUR',
      },
    },
    {
      limit: 1,
      duration: 60,
      enabled: false,
      type: 'AD',
      durationUnit: {
        displayName: 'Days',
        key: 'DAY',
      },
    },
  ],
  viewabilityVendorList: [{ key: 'MOAT', displayName: 'Moat' }],
  adTagList: [
    {
      id: 'billable-tag-id',
      event: {
        displayName: 'Complete',
        key: 'COMPLETE',
      },
      isVerified: true,
      type: {
        displayName: 'Billable',
        key: 'BILLABLE',
      },
      unDeletable: false,
      url: 'https://www.hehehaha.com',
      isIas: null,
    },
  ],
  targetingTermValues: mockAdFormTargetingTermValues,
  targetingRule: { id: '', definition: null },
  actualStart: '2022-11-11T23:18:24Z',
  newAdTag: { url: '' },
  newTargeting: { category: null, dimension: null, values: [] },
  id: 'test-ad-id',
  status: {
    displayName: 'Off',
    key: 'OFF',
  },
  lineItem: {
    id: 'line-item-id',
    parentLineOmsLink: {
      id: 'parent-line-oms-link-id',
    },
  },
};
