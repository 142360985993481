import React from 'react';

import { StatusModal } from '../../../../../../StatusModal';
import type { AssetExtended } from '../../../../../types/extended';
import { PositionedAssetStatusPill } from './styles';

export type Props = {
  asset: AssetExtended;
};

export const StatusModalWithPill = ({ asset }: Props): React.JSX.Element => {
  const { review, transcodeStatus, ingestionTrial, reviewTask } = asset;

  const pill = (
    <PositionedAssetStatusPill
      spReviewStatus={reviewTask?.spReview}
      reviewStatus={review}
      transcodeStatus={transcodeStatus}
      ingestionStatus={ingestionTrial?.trialStatus}
    />
  );

  if (
    ingestionTrial?.trialStatus &&
    ingestionTrial.trialStatus !== 'INGESTING' &&
    ingestionTrial.trialStatus !== 'INGESTION_COMPLETE'
  ) {
    return (
      <StatusModal activator={pill} title="Ingestion Error">
        <p>The follow error occurred during ingestion of this asset:</p>
        <pre>{JSON.stringify(ingestionTrial, undefined, '  ')}</pre>
      </StatusModal>
    );
  }

  return pill;
};
