import './AssetDetails.scss';

import { IconShare } from '@hulu-react-style-components/icons';
import { AssetDownloadButton } from 'mission-control-common-components';
import React from 'react';

import type { AssetTagV5 } from '../../apis/graphql';
import { AssetTypeV5 } from '../../apis/graphql';
import type { Nullable } from '../../models/utils';
import bem from '../../utils/bem';
import { formatBytes, formatDate, formatDuration, stringifyTagList } from '../../utils/formatting';
import Alert, { AlertType } from '../Alert';
import Pill from '../Pill';
import { TRANSCODE_PILL_COLORS } from '../Pill/constants';
import Tooltip from '../Tooltip';

export interface AssetDetailsProps {
  assetDetailsUrl: string;
  assetTagList?: AssetTagV5[];
  createdAt: Date;
  fileSize: Nullable<number>;
  filename?: string;
  fileUrl: string;
  review: string;
  transcodeStatus?: string;
  transcodingDetails: { name: string; message: string };
  type: string | undefined;
  videoDuration?: number;
}

// Always link to prod snapshot UI
// TODO: Use the correct Snapshot URL when available
export const BASE_SNAPSHOT_URL = ''; //'http://snapshot-ui.prod.hulu.com/?source=path&validate=1&path=';
const separatorString = ' • ';

export const makeMetadataString = (createdAt: Date, fileSize: Nullable<number>, videoDuration?: number): string => {
  const components: string[] = [];

  if (videoDuration) {
    components.push(formatDuration(videoDuration));
  }
  if (fileSize) {
    components.push(formatBytes(fileSize));
  }

  components.push(`Uploaded ${formatDate(createdAt, false)}`);

  return components.join(separatorString);
};

const [block, element] = bem('asset-details');

function AssetDetails({
  assetDetailsUrl,
  assetTagList,
  createdAt,
  filename,
  fileSize,
  fileUrl,
  review,
  transcodeStatus,
  transcodingDetails,
  type,
  videoDuration,
}: AssetDetailsProps): JSX.Element {
  const isPending = review === 'PENDING';
  const isDisplayAsset = type === AssetTypeV5.Display;
  const isTextAsset = type === AssetTypeV5.Text;

  const isTranscodingErrorAlertShown =
    (transcodingDetails.message || transcodingDetails.name) && !isDisplayAsset && !isTextAsset;
  const isAssetDetailsLinkShown = assetDetailsUrl && !isTextAsset;

  return (
    <div className={block()}>
      {!isTextAsset && (
        <div className={element('header')} data-testid="asset-details-header">
          <Tooltip
            id={`asset-name-tooltip`}
            message={'Original asset name. May differ from file name if same asset is uploaded multiple times.'}
          >
            <p className={element('name')}>{filename}</p>
          </Tooltip>
          <div className={element('download')}>
            <AssetDownloadButton filename={filename} url={fileUrl} />
          </div>
        </div>
      )}
      {transcodeStatus && (
        <div className={element('transcoding-status')}>
          Transcoding Status:
          <Pill
            className={element('transcoding-status-pill')}
            text={transcodeStatus}
            color={TRANSCODE_PILL_COLORS[transcodeStatus]}
          />
        </div>
      )}
      {isTranscodingErrorAlertShown && (
        <div className={element('transcoding-details')}>
          <Alert
            headerText={isPending ? '' : `Transcoding Error: ${transcodingDetails.name || ''}`}
            type={isPending ? AlertType.WARNING : AlertType.ERROR}
            bodyText={
              isPending ? 'ASSET PENDING PRE-QC INGESTION. Check back later.' : transcodingDetails.message || ''
            }
          />
        </div>
      )}
      <p className={element('metadata')}>
        {makeMetadataString(createdAt, fileSize, videoDuration)}
        {isAssetDetailsLinkShown && (
          <>
            {separatorString}
            <a className={element('review-task-link')} href={assetDetailsUrl} target="_blank" rel="noopener noreferrer">
              <span>Asset Details</span> <IconShare title="Asset Details external link" />
            </a>
          </>
        )}
      </p>
      <div className={element('asset-tags')}>
        <p className={element('label')}>{'Asset tags:'}</p>
        <p className={element('tags')}>{stringifyTagList(assetTagList ?? [])}</p>
      </div>
    </div>
  );
}

export default AssetDetails;
