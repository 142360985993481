import type { ValueOf } from '../../../../types/util';
import { TranscodeStatus, TranscodeStatusMap } from '../constants';
import type { StatusLabel } from '../types';
import { StageLabels } from '../types';
import { LabelType } from './LabelType';

const SuccessStatusList = [
  TranscodeStatus.PRE_PROCESSING_COMPLETE,
  TranscodeStatus.PUBLISHING_COMPLETE,
  TranscodeStatus.STREAMABLE,
];

const ErrorStatusList = [
  TranscodeStatus.PRE_PROCESSING_ERROR,
  TranscodeStatus.PRE_PROCESSING_ABORT,
  TranscodeStatus.PUBLISHING_ERROR,
  TranscodeStatus.PUBLISHING_ABORT,
  TranscodeStatus.STREAMABLE_CHECKING_ERROR,
  TranscodeStatus.STREAMABLE_CHECKING_ABORT,
  TranscodeStatus.STREAMABLE_ERROR,
];

export function getTranscodeLabel(status?: string | null): StatusLabel {
  if (!status || !Object.keys(TranscodeStatus).includes(status)) return null;

  let type: ValueOf<typeof LabelType> = LabelType.IN_PROGRESS;

  if (SuccessStatusList.includes(status)) type = LabelType.SUCCESS;
  if (ErrorStatusList.includes(status)) type = LabelType.ERROR;

  return {
    label: TranscodeStatusMap[status as keyof typeof TranscodeStatus],
    type,
    stage: StageLabels.Transcode,
  };
}
