import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import React from 'react';
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import Loader from '../../../../Loader';
import type { AssetExtended } from '../../../types/extended';
import { AssetPreviewCard } from '../../molecules/AssetPreviewCard';
import { NO_ASSETS_TEXT } from './constants';
import { EmptyMessage, LoaderWrapper, SwiperWrapper, Title } from './styles';

export type Props = {
  title: string;
  assets?: AssetExtended[];
  loading?: boolean;
  creativeId: string;
  titleFontSize?: string;
};

export const AssetSlider = ({ assets, title, titleFontSize, loading, creativeId }: Props): React.JSX.Element => {
  SwiperCore.use([Navigation]);

  const assetsComponent =
    assets && assets.length ? (
      assets.map((asset) => (
        <SwiperSlide key={asset.id}>
          <AssetPreviewCard asset={asset} creativeId={creativeId} />
        </SwiperSlide>
      ))
    ) : (
      <EmptyMessage>{NO_ASSETS_TEXT}</EmptyMessage>
    );

  return (
    <SwiperWrapper data-testid="asset-slider">
      <Title $fontSize={titleFontSize}>{title}</Title>
      <Swiper navigation slidesPerView={3} slidesPerGroup={3}>
        {loading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          assetsComponent
        )}
      </Swiper>
    </SwiperWrapper>
  );
};
