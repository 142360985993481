import './ReviewStatusModal.scss';

import { Modal } from 'antd';
import React from 'react';

import type { AssetRejectionReasonV5, BaseAssetV5 } from '../../apis/graphql';
import type { AssetRejectionDisplayValues } from '../../configs';
import { assetRejectionReasonMap } from '../../configs';
import bem from '../../utils/bem';
import { enumToTitleCase } from '../../utils/formatting';
import Button from '../Button';
import { ToggleSection } from '../ToggleSection';
import { useStatusModal } from './hooks';
import { useAssetComments } from './reviewStatusModalUtils';

interface RejectionReasonToggleProps {
  name: string;
  rejectionReasons: string | AssetRejectionReasonV5[];
  toggles: boolean[];
  toggleAt: (index: number, value: boolean) => void;
  reasonIndex?: number;
  index?: number;
  assetId?: string;
}

const NO_REJECTION_REASONS_LABEL = 'No rejection reasons provided.';
const LOADING_COMMENTS_LABEL = 'Loading comments...';
const ERROR_LOADING_COMMENTS_LABEL = 'Error loading comments.';
const NO_COMMENTS_AVAILABLE_LABEL = 'No comments available for this asset.';

function RejectionReasonToggle({
  rejectionReasons,
  toggles,
  toggleAt,
  index = 0,
  assetId,
}: RejectionReasonToggleProps): JSX.Element {
  const { changeLogComments, loading, error } = useAssetComments(assetId);

  // Flatten rejection reasons into a simple list
  const entries = Object.entries(assetRejectionReasonMap);
  const filteredRejectionReasons: [string, AssetRejectionDisplayValues][] = entries.filter(([key]) =>
    rejectionReasons.includes(key as AssetRejectionReasonV5)
  );

  return (
    <>
      {/* Rejection Reasons */}
      {filteredRejectionReasons.length > 0 ? (
        <div className={modalElement('rejection-reasons')}>
          <ul>
            {filteredRejectionReasons.map(([, value]: [string, AssetRejectionDisplayValues]) => (
              <li key={value.title} className={modalElement('issue-description')}>
                {enumToTitleCase(value.title.replace(/_/g, ' '))}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className={modalElement('issue-description')}>{NO_REJECTION_REASONS_LABEL}</div>
      )}

      {/* Comments Dropdown */}
      <ToggleSection
        handleToggle={() => toggleAt(index, toggles[index])}
        isExpanded={toggles[index] === undefined ? false : toggles[index]}
        key={`toggle-comments-${index}`}
        title={'Comments'}
      >
        {loading && <div className={modalElement('issue-description')}>{LOADING_COMMENTS_LABEL}</div>}
        {error && <div className={modalElement('issue-description')}>{ERROR_LOADING_COMMENTS_LABEL}</div>}
        {!loading && !error && changeLogComments.length === 0 && (
          <div className={modalElement('issue-description')}>{NO_COMMENTS_AVAILABLE_LABEL}</div>
        )}
        {changeLogComments.length > 0 &&
          changeLogComments.map((comment, idx) => (
            <div key={idx} className={modalElement('comment')}>
              <div className={modalElement('comment-text')}>{comment.comment}</div>
              <div className={modalElement('comment-divider')} />
              <div className={modalElement('comment-date')}>{comment.date}</div>
            </div>
          ))}
      </ToggleSection>
    </>
  );
}

export interface ReviewStatusModalProps {
  asset: BaseAssetV5;
  customActivator?: string | React.ReactNode;
}

const [block, element] = bem('review-status-container');
const [modalBlock, modalElement] = bem('review-status-modal');

const SEE_REASON_LABEL = 'See reason';
const CLOSE_LABEL = 'Close';
const PLEASE_RESUBMIT_MESSAGE = 'Please address the below issues and re-submit creative for review.';

/**
 * Status Modal including a Rejection Reason toggle list
 * TODO: Separate review-specific logic & reuse generic StatusModal component
 */
export function ReviewStatusModal({ asset, customActivator }: ReviewStatusModalProps): React.JSX.Element {
  const { closeModal, openModal, toggles, toggleAt, visible } = useStatusModal(asset);
  const hasDetails = (!!asset.rejectionReasons && asset.rejectionReasons.length) || false;

  return (
    <div className={block()}>
      {customActivator ? (
        <span className={element('activator')} onClick={openModal}>
          {customActivator}
        </span>
      ) : (
        <>
          {hasDetails && (
            <span data-testid="rejection-rejection-button" className={element('text')} onClick={openModal}>
              {`${SEE_REASON_LABEL}${
                asset.rejectionReasons?.length !== undefined && asset.rejectionReasons?.length > 1 ? 's' : ''
              }`}
            </span>
          )}
        </>
      )}
      {visible && (
        <Modal
          onCancel={closeModal}
          visible={visible}
          title="Reasons for Rejection"
          className={modalBlock()}
          footer={
            <Button key="submit" aria-label="close" tabIndex={0} onClick={closeModal}>
              {CLOSE_LABEL}
            </Button>
          }
        >
          {PLEASE_RESUBMIT_MESSAGE}
          <>
            <p className={modalElement('asset-name')}>{asset.name}</p>
            <RejectionReasonToggle
              key={`rejection-reason-${asset.id}`}
              name={asset.name}
              reasonIndex={0}
              rejectionReasons={asset.rejectionReasons || []}
              toggleAt={toggleAt}
              toggles={toggles}
              assetId={asset.id}
            />
          </>
        </Modal>
      )}
    </div>
  );
}
