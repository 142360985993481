import styled from 'styled-components';

import { gridPx } from '../../../../../../styles/styled/constants';
import { AssetStatusPill } from '../../../../../atoms/AssetStatusPill';

export const PositionedAssetStatusPill = styled(AssetStatusPill)`
  position: absolute;
  top: 0.625em;
  left: 0.625em;
  max-width: calc(100% - 1.25em);
  word-break: break-word;
  display: flex;
  flex-wrap: wrap;

  .label {
    flex-grow: 1;
    margin-right: ${gridPx(0.5)};
  }

  .review-status-container {
    width: auto;
  }
`;
