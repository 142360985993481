import { gql } from '@apollo/client';

export const SEARCH_CREATIVES = gql`
  query SearchCreatives(
    $filterOptions: BaseCreativeFilterOptionsV5
    $paginationOptions: PaginationOptions
    $sortOptions: SearchCreativeSortOptions
  ) {
    searchCreatives: searchCreativesV5(
      filterOptions: $filterOptions
      paginationOptions: $paginationOptions
      sortOptions: $sortOptions
    ) {
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
